/**
 * ACTIONS
 */
import { firebaseAction, firestoreAction } from "vuexfire";
import { firebaseApp, firebase } from "@/config/firebase";

/**
 * ADD FOLLOWER
 */
const addFollower = ({ state, commit }, value) => {
  commit("ADD_FOLLOWER", value);
};

/**
 * BIND APP SETTINGS
 * Real-time settings in RTDB.
 */
const bindAppSettings = firebaseAction(({ bindFirebaseRef }) => {
  const ref = firebaseApp.database().ref("appSettings");
  return bindFirebaseRef("appSettings", ref);
});

/**
 * BIND FOLLOW TITLES
 * Real-time server logs in Firestore.
 */
const bindFollowTitles = firestoreAction(({ bindFirestoreRef }) => {
  const ref = firebaseApp
    .firestore()
    .collection("followTitles")
    .doc("available");
  return bindFirestoreRef("followTitles2", ref);
});

/**
 * BIND SERVER LOGS
 * Real-time server logs in Firestore.
 */
const bindServerLogs = firestoreAction(({ bindFirestoreRef }, id) => {
  const ref = firebaseApp
    .firestore()
    .collection("logs")
    .doc(id);
  return bindFirestoreRef("serverLogs", ref);
});

/**
 * REMOVE LOGS
 */
const removeLogs = ({ state, commit }, docId) => {
  console.log("Removed log:", docId);
  firebase
    .firestore()
    .collection("logs")
    .doc(docId)
    .delete();
};

/**
 * SET BROADCASTING
 */
const setBroadcasting = ({ state, commit, dispatch }, value) => {
  commit("SET_BROADCASTING", value);
};

/**
 * EXPORT
 */
export default {
  addFollower,
  bindAppSettings,
  bindFollowTitles,
  bindServerLogs,
  removeLogs,
  setBroadcasting,
};
