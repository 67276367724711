/*
	SHUFFLE PLAYLIST
*/
//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const shufflePlaylist = ({ state, getters, commit }, payload) => {

	// Strip all letters to create numeric seed.
	// Remember, the task.id is the local (numeric), task.fbId is remote and synced (alphanumeric).
	const seed = payload.seed.toString().replace(/\D/g,'');

	console.warn('Shuffle seed: '+seed);

	//--------------

	// This issue is if you SHUFFLE > SELECTION > UNSHUFFLE > REFRESH = loses index

	// fails: SELECTION > SHUFFLE > REFRESH

	// it's because the routine selects the video based on index, instead of value

	const activeVideoIndex = getters.getActiveVideoIndex;
	const playlistCopy = state.playlist.slice();
	let splicedItem = null;

	//--------------
	// TODO: find way to maintain active index in shuffled array

	// If there's a selection, remove video obj from array.
	if (activeVideoIndex !== -1) {
		splicedItem = playlistCopy.splice(activeVideoIndex, 1)[0];
	}
	//--------------

	// Create new array to replace old one.
	// https://stackoverflow.com/questions/1862130/strip-all-non-numeric-characters-from-string-in-javascript
	const shuffledPlaylist = shuffle(playlistCopy, seed);

	// Insert back into array at correct index.
	if (splicedItem) {
		shuffledPlaylist.splice(activeVideoIndex, 0, splicedItem);
	}

	commit('SHUFFLE_PLAYLIST', shuffledPlaylist);
}



// https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
function shuffle(array, seed) {
	var m = array.length, t, i;
	while (m) {
		i = Math.floor(random(seed) * m--);
		t = array[m];
		array[m] = array[i];
		array[i] = t;
		++seed
	}
	return array;
}

function random(seed) {
	var x = Math.sin(seed++) * 10000;
	return x - Math.floor(x);
}