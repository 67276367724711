/**
 * NOTES:
 *
 * https://stackoverflow.com/questions/16452074/firebase-persistance-ondisconnect-with-multiple-browser-windows
 * https://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
 * https://github.com/ai/visibilityjs
 */

import bindClients from "./bindClients";
import bindClientDevices from "./bindClientDevices";
import bindUser from "./bindUser";
import logout from "./logout";
import setUserPresence from "./setUserPresence";
import setUser from "./setUser";
import setClientDeviceId from "./setClientDeviceId";
import setClientStatus from "./setClientStatus";

/**
 * EXPORT
 */
export default {
  bindClients,
  bindClientDevices,
  bindUser,
  logout,
  setClientDeviceId,
  setClientStatus,
  setUser,
  setUserPresence,
};
