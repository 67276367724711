/**
 * NOTES:
 *
 * https://stackoverflow.com/questions/16452074/firebase-persistance-ondisconnect-with-multiple-browser-windows
 * https://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
 * https://github.com/ai/visibilityjs
 */

import { firebaseApp } from "@/config/firebase";

/**
 * SET USER
 */
export default async function setUser({ dispatch }) {
  const { uid } = firebaseApp.auth().currentUser;

  if (!uid) {
    console.error("Oops! `auth().currentUser.uid` was `null`.");
    return false;
  }

  /**
   * BIND USER
   */
  const userData = await dispatch("bindUser", uid);

  if (!userData) {
    console.error("Oops! bindUser returned empty. Logging out.");
    dispatch("logout");
    // alert("Check setUser.js - would log you out here.", { userData, uid });
    return false;
  }

  return userData;
}
