/**
 * GET ALL COOLDOWNS
 */
const getAllCooldowns = (state) => state.cooldowns;

/**
 * EXPORT
 */
export default {
  getAllCooldowns,
};
