<template>
  <transition
    enter-active-class="animated fast fadeIn"
    leave-active-class="animated fast fadeOut"
    mode="out-in"
  >
    <div v-show="isScreenLockActive" class="screen-lock-parent">
      <!-- LOCKED -->
      <div v-if="isClientDeviceLocked">
        <h2>
          <i class="d-block fa fa-wifi animated heartBeat mb-2"></i>
          The app is running elsewhere.
        </h2>
        <button class="btn btn-primary font-weight-bold" @click="onReactivate">
          Run here
        </button>
      </div>

      <!-- MAINTENANCE -->
      <div v-else-if="isClientMaintenance">
        <h2 class="mb-2">
          <i class="d-block fa fa-wrench mb-2"></i>
          Sorry, we're down for maintenance right now.
        </h2>
        <p>
          Check back soon!
        </p>
      </div>

      <!-- SLEEPING -->
      <div v-else-if="isClientDeviceSleeping">
        <h2>
          <i class="d-block fa fa-moon-o animated heartBeat"></i>
          The app is currently sleeping.
        </h2>
        <button class="btn btn-primary font-weight-bold" @click="onReactivate">Wake up!</button>
      </div>

      <!-- UNAUTHORIZED -->
      <div v-else-if="isClientUnauthorized">
        <h2 class="mb-2">
          <i class="d-block fa fa-exclamation-triangle mb-2"></i>
          Sorry, only insiders can access this right now.
        </h2>
        <p class="mb-4">Interested? Let us know on Discord.</p>
        <p v-if="countdown !== null">Redirecting in {{ countdown }}</p>
      </div>

      <!-- UNSUPPORTED -->
      <div v-else-if="isClientUnsupported">
        <h2 class="mb-2">
          <i class="d-block fa fa-exclamation-triangle mb-2"></i>
          Your browser has cookies disabled.
        </h2>
        <p class="mb-4">This app requires cookies enabled to authenticate.</p>
        <p v-if="countdown !== null">Redirecting in {{ countdown }}</p>
      </div>

      <!-- UNSUPPORTED SCREEN SIZE -->
      <!-- <div class="unsupported-screen-size">
        <h2>Screen is too small.<br>
          <i class="fa fa-long-arrow-left"></i>
          <i class="fa fa-frown-o fa-spin mx-2"></i>
          <i class="fa fa-long-arrow-right"></i>
        </h2>
      </div> -->
    </div>
  </transition>
</template>

<script>
  /* eslint-disable implicit-arrow-linebreak */
  import { mapGetters } from "vuex";
  import { firebaseApp } from "@/config/firebase";

  export default {
    data() {
      return {
        countdown: null,
        countdownTimeoutInstance: null,
      };
    },
    computed: {
      ...mapGetters({
        appSettings: "global/getAppSettings",
        clientDevice: "user/getClientDevice",
        clientStatus: "user/getClientStatus",
        userData: "user/getUserData",
      }),
      isScreenLockActive() {
        return (
          this.isClientDeviceLocked ||
          this.isClientMaintenance ||
          this.isClientDeviceSleeping ||
          this.isClientUnauthorized ||
          this.isClientUnsupported
        );
      },
      isClientDeviceLocked() {
        return this.userData?.roles.includes("administrator") ? false : this.clientDevice?.isLocked; // todo: prevent task syncing
      },
      isClientMaintenance() {
        return this.appSettings?.isDownForMaintenance;
      },
      isClientDeviceSleeping() {
        return this.clientDevice !== {} && !this.clientDevice;
      },
      isClientUnauthorized() {
        return this.clientStatus === "unauthorized";
      },
      isClientUnsupported() {
        return this.clientStatus === "unsupported"; // Remember: no cookie
      },
    },
    watch: {
      clientStatus: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal === "maintenance" || newVal === "unauthorized" || newVal === "unsupported") {
            this.countdownToLogout();
          }
          if (newVal === "loggedOut") {
            this.logout();
          }
        },
      },
    },
    created() {
      // https://stackoverflow.com/questions/65837905/how-to-log-user-out-if-disabled-or-deleted-firebase-auth
      firebaseApp.auth().onAuthStateChanged((user) => {
        if (user === null) {
          this.logout();
        }
      });
    },
    beforeDestroy() {
      this.resetCountdown();
    },
    methods: {
      logout() {
        firebaseApp
          .auth()
          .signOut()
          .then(this.redirectHome);
      },
      redirectHome() {
        this.$stopAllSounds();
        this.$store.dispatch("user/setClientStatus", "");

        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
      },
      resetCountdown() {
        clearTimeout(this.countdownTimeoutInstance);
        this.countdown = null;
      },
      onReactivate() {
        window.location.reload();
      },
      countdownToLogout() {
        this.resetCountdown();
        const FIVE_SECONDS = 5;
        this.countdown = FIVE_SECONDS;

        const runTimeout = () => {
          this.countdownTimeoutInstance = setTimeout(() => {
            if (this.countdown > 0) {
              this.countdown -= 1;
              runTimeout();
            } else {
              this.logout();
            }
          }, 1000);
        };

        runTimeout();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .screen-lock-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.8);
    backdrop-filter: blur(10px);
    font-family: "Avenir Next";
    text-align: center;
    color: white;
    z-index: 2147483647; // [LOCKED] - highest possible.

    @media (max-width: 1023px) {
      // display: flex !important;
    }

    .fa {
      color: #e31325;
    }
  }

  .unsupported-screen-size {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;

    @media (min-width: 1024px) {
      display: none;
    }
  }
</style>
