/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable implicit-arrow-linebreak */

import deleteAllTasks from "../../../services/firestore/deleteAllTasks";
import getTaskMechanics from "./_getTaskMechanics.helper";

/**
 * ACTIVATE TASKS
 */
export default async function activateTasks({ dispatch, state, getters, commit }, tasks) {
  const finalTasks = tasks.map((task) => {
    const {
      isInit,
      isInitExplicitReset,
      isOverrideAndReplace,
      isOverrideAndReplaceAndToggle,
      isStack,
      isStackAndToggle,
      isTemporal,
    } = getTaskMechanics(task);

    //------------------------------------------------------------
    // INIT - Hardcoded defaults
    //------------------------------------------------------------

    if (isInit) {
      commit("CLEAR_ALL_TASKS");

      const devTasks = window.debug
        ? [{ uuid: "8c368523-1822-4f9a-9f89-49d7cf32255a", canceled: false }]
        : [];

      const initTasks = [...devTasks, ...state.tasksDefault];
      dispatch("runTasks", initTasks);

      // Delete all database tasks when invoked explicitly (not on browser refresh).
      if (isInitExplicitReset) {
        deleteAllTasks();
      }
    }

    //------------------------------------------------------------
    // 1. OVERRIDE AND REPLACE
    // Eg. SCENE, only one can exist at once.
    //  - OR -
    // 2. isOverrideAndReplaceAndToggle
    // Eg. Running a program (on/off), some sounds (interrupt), some effects (TV Off)
    //------------------------------------------------------------

    if (isOverrideAndReplace || isOverrideAndReplaceAndToggle) {
      const existingTasksWithGroup = getters.getAllTasks.filter(
        (existingTask) =>
          existingTask.group === task.group &&
          !existingTask.canceled &&
          // Important: handles delayStart task with no explicit run (canceled:false) - will not cancel a null, sleeping ticket.
          // Eg. book transition triggers TV static.
          !existingTask.sleeping
      );

      // Existing tasks exist, BUT 'sleeping' tasks can't cancel/toggle others until they wake.
      // Eg. consider the case of a scene with delayStart.
      if (existingTasksWithGroup.length && !task.delayStart) {
        dispatch("cancelTasks", existingTasksWithGroup);

        /**
         * (DE)SELECT CURRENT TASK (this hasn't reached the store yet)
         *
         * We need `true` (since existing tasks found), unless task is explicitly passing `canceled:false`.
         * 1. pass nothing (null) = true (auto)
         * 2. pass `true` = true (explicit)
         * 3. pass `false` = false (explicit)
         *
         * Update 10.08.21 - firebase queries weird with null, use zero instead.
         * Remember: you can explicitly pass `canceled:false` in runTasks, to prevent a 'blind' toggle.
         * Default is`null`, which toggles.
         */
        if (isOverrideAndReplaceAndToggle) {
          task.canceled = task.canceled === null || task.canceled === 0 || task.canceled;
        }
      }
    }
    //------------------------------------------------------------
    // STACK
    // Multiple can exist at anytime
    //------------------------------------------------------------

    // if (isStack) {}

    //------------------------------------------------------------
    // STACK AND TOGGLE
    // Examples: program mute switch etc
    // BEHAVIOR: stack but ability to turn off individuals.
    // If the task has an argument, don't turn the whole group off.
    // HOW TO THINK OF 'CANCEL' - imagine it's a button on a stream deck, and it turns on and off.
    // there has to be a way to avoid canceling others when an arg is present
    //------------------------------------------------------------

    if (isStackAndToggle) {
      const existingTasksWithGroupAndData = getters.getAllTasks.filter(
        (item) =>
          item.group === task.group &&
          item.data.value === task.data.value &&
          // If cancelByArg: has to be an <arg> match to toggle.
          (!item.cancelByArg ||
            (item.cancelByArg &&
              item.data.arg &&
              item.data.arg.toString() === task.data.arg.toString())) &&
          // if cancelByVal: has to have data.val match to toggle - used by user sound effects. May merge one day.
          (!item.cancelByVal ||
            (item.cancelByVal && item.data.value && item.data.value === task.data.value)) &&
          // cancelById - experimental - this should replace the others
          (!item.cancelById || (item.cancelById && item.id && item.id === task.id)) &&
          !item.canceled &&
          !item.sleeping // *new* working??
      );

      // Existing tasks exist, BUT 'sleeping' tasks can't cancel/toggle others until they wake.
      if (existingTasksWithGroupAndData.length && !task.delayStart) {
        dispatch("cancelTasks", existingTasksWithGroupAndData);

        // Update 10.08.21 - firebase queries weird with null, use zero instead.
        // Remember: you can explicitly pass `canceled:false` in runTasks, to prevent a 'blind' toggle. Default is `null`, which toggles.
        task.canceled = task.canceled === null || task.canceled === 0 || task.canceled;
      }
    }

    //------------------------------------------------------------
    // SET TIMING FUNCTIONS AND IMMEDIATELY RUN
    // Remember: sets the task as 'sleeping' if delayStart.
    // If it's a component, let it load then apply the timings.
    //------------------------------------------------------------

    if (isTemporal) {
      dispatch("setTimingFunctions", task);

      // EXECUTE ONLY IF...
      // NOT queued.
      // NOT a component.
      // Why? The component will take care of itself after load.
      if (
        !task.componentData.queued &&
        (!task.componentData.path || task.componentData.progressPerc < 100)
      ) {
        // RUN
        task.timingFunction();
      }
    }

    //------------------------------------------------------------
    // ADHOC FOR ONE PURPOSE - THIS IS NOT GOOD - THIS CLEARS REQUESTS, NEEDS BETTER API
    // CANCEL ALL GROUP AND DATA (for clear all)
    // This is somewhat experimental, designed for the **media poster**
    // before it gets rewritten to using playlist queuing.
    //------------------------------------------------------------

    if (task.cancelAllGroupAndData) {
      const existingTasksWithGroupAndData = state.tasks.filter(
        (item) => item.group === task.group && item.data.value === "Request"
      );

      // CANCEL TASKS
      dispatch("cancelTasks", existingTasksWithGroupAndData);
    }

    return task;
  });

  /**
   * CLEAN UP AFTER CANCELED TASKS IN BATCH
   */

  // Sleeping tasks are invisible until awake.
  const canceledTasks = finalTasks.filter((task) => task.canceled && !task.sleeping);

  if (canceledTasks.length) {
    dispatch("cancelTasks", canceledTasks);
  }

  /**
   * ADD FINAL TASKS
   */
  commit("ADD_TASKS", finalTasks);
}
