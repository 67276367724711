/*
	SET ACTIVE VIDEO
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firebaseApp } from '@/config/firebase.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const setActiveVideo = ({ getters }, index) => {

	// Make copy of array.
	const playlistClone = getters.getPlaylist.slice();

	// Toggle flagging.
	playlistClone.forEach(item => item.playerVars.active = false);
	playlistClone[index].playerVars.active = true;

	// Update database.
	firebaseApp.firestore().collection('playlists').doc('public')
	.update({
		videos: playlistClone
	});
}