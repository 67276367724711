/*
    ASSETS-MANIFEST.JS

    - Last updated: 06.12.19
    - NOTES:
        https://stackoverflow.com/questions/29891458/webpack-require-every-file-in-directory
        https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
        https://webpack.js.org/guides/dependency-management/#require-context

        * For technical reasons, you can only load a folder that contains an assets folder.
          you can't load a specific folder inside of assets, (eg. /assets/audio)
*/

const ASSETS = require.context("@/assets", true, /\.(mp3|mp4|png|svg|jpg|gif)$/); // eot|ttf|woff
const COMPONENTS = require.context("@/components", true, /\.(mp3|mp4|png|svg|jpg|gif)$/);

window.GLOBAL_ASSETS_MANIFEST = [...addToManifest(ASSETS), ...addToManifest(COMPONENTS)];

function addToManifest(context) {
  const files = context.keys().map((localPath) => {
    // -- EXAMPLE --
    // localPath: './audio/ace_ventura_call-you-sometime.mp3'
    // distPath:  '/assets/media/ace_ventura_call-you-sometime.47d74d2a.mp3'

    const distPath = context(localPath);
    const fileExt = /(?:\.([^.]+))?$/.exec(distPath)[1]; // eg. 'png'
    const fileName = distPath
      .split("/")
      .slice(-1)[0]
      .split(".")[0]; // assets/media/book-stinger (note: no webpack id)

    // Eg. "518493928(TheTestingHour).mp3" <-- all (meta) is discarded - if existing.
    const fileNameStrippedMeta = fileName.replaceAll(/\([^)]*\)/g, "").trim();

    // if (fileExt === "mp3") {
    //   console.log(fileNameStrippedMeta);
    // }

    return {
      // Only audio files need unique ids for preload.js
      id: fileExt === "mp3" ? fileNameStrippedMeta : "",

      ext: fileExt,

      // This points back to the local assets directory.
      folder: localPath.split("/assets")[0],

      // This points to where the *final* files rest.
      src: distPath,
    };
  });

  return files;
}

// console.log(GLOBAL_ASSETS_MANIFEST);

//-----------------------------------------------------------------
// COMPOSE MANIFEST
// EG. ['./MitchOS/', './MitchOS/Transitions/', './audio/']
// Usage: composeManifest(['./audio/', './MitchOS/Transitions/']
//-----------------------------------------------------------------

window.composeManifest = (paths, ignoredFileTypes) => {
  const manifest = [];

  paths.forEach((item) => {
    window.GLOBAL_ASSETS_MANIFEST.forEach((file) => {
      let failedChecks = 0;

      // The folder must be present in global manifest.
      if (file.folder.search(item) !== -1) {
        // If ignored types are passed, exclude.
        // EG..., { ignore: ['mp3'] });
        if (ignoredFileTypes && ignoredFileTypes.ignore.length) {
          ignoredFileTypes.ignore.forEach((ignoredType) => {
            // If a dot is prefixed, strip it (eg. '.mp3' = 'mp3')
            const finalType = ignoredType.charAt(0) === "." ? ignoredType.slice(1) : ignoredType;
            if (file.ext === finalType) failedChecks += 1;
          });
        }

        // If no failed checks, push into final manifest for loading.
        if (!failedChecks) manifest.push(file);
      }
    });
  });

  return manifest;
};
