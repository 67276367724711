import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

/**
 * STATE
 */
const state = {
  activeSceneName: "",
  inputs: [],
  isWebSocketConnected: null,
  serverIP: "ws://localhost",
  serverPort: "4455",
  serverPassword: "2EFIfb2TDrqVK8oz",
  sources: [],
};

/**
 * EXPORT
 */
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
