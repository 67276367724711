/*
	CLEAR PLAYLIST

	Delete multiple: https://github.com/googleapis/nodejs-firestore/issues/64 ??
*/
//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const clearPlaylist = ({ dispatch, getters }, payload) => {

	// Create array of indices and pass along to remove.
	const indices = getters.getPlaylist.map((item, index) => index);
	dispatch('removeFromPlaylist', indices);
}