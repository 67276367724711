/**
 * STATE
 */
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  appSettings: {},
  broadcaster: "TheMitchingHour",
  broadcasting: false, // true: supplies timestamp.
  serverLogs: [],
  sessionFollowers: [], // log follows during session to prevent spam
  followTitles: [
    "As Nanomachines",
    "As the Length of Time We've Been Offline",
    "As Mitch's Hiatus...es",
    "As Mitch's Jar of Lies",
    "As the Number of Huey Lewises We've Lost During Mitch's Absence",
    "As the Face of Nu-metal",
    "As Faizy, King of the Raid Rats",
    "As Faizy, King of Red Dead Redemption Spoilers",
    "As Faizy's Institute for Gifted Young Streamers Who Wish to Master the Art of Raid Ratting",
    "As Mad Max Returning to the Office on Monday",
    "As Fox Mulder's Chicken Pox",
    "As Drop Bear #7",
    "As the Goosebumps Book with the Orgy In It",
    "As the Executive Producer of the First Three Seasons of Mad TV",
    "As Ariana Grande's Grande Toffee Flavoured Whatever",
    "As Turboman's Turbo Spanks",
    "As George Clooney's Nipples in Batman & Robin",
    "As Chris O'Donnell's Nipples in Batman & Robin",
    "As Batman and Throbbin'",
    "As Maculey Culkin's Sulky Hulk Impression",
    "As the Hulk's Impression of Maculey Culkin Malting",
    "As Clippy from Microsoft Word (RIP)",
    "As Tim Curry's Spicy Curry Puffs",
    "As Tim Curry's Heavy Lorry",
    "As Tim Curry's Heavy Lorry Haulin' Spicy Curry Puffs",
    "As Marilyn Manson's Removed Rib",
    "As Terry's Removed Rib",
    "As Marilyn Manson Opening for Hanson",
    "As Limp Bizkit's Gift Bag of Biscuits",
    "As Tim Minchin's Munchin",
    "As Kevin Bacon and Egg McMuffin",
    "As the Legless Protester Outside the Ministry of Silly Walks",
    "As the Pig Who Provided Blood in Carrie",
    "As the Event Horizon's Hellish Health Care",
    "As Biff Tannen's Boxset of Buffy",
    "As Buffy's Signed Poster of Biff Tannen, Tanning",
    "As the Headless Horseman's Bald Head",
    "As the Headless Horseman's Halloween Costume of BoJack Horseman",
    "As Scream 1, 2 and 4 But Not 3",
    "As Jason Vorhees Rookie Hockey Performance",
    "As Jason Vorhees Nest of Bees",
    "As the Leo Who Made It From Titanic's Alternate Timeline",
    "As the Silence of the Lamb.. Chops Play Along Where People Come to Sing-a-long.",
    "As Elijah Wood's Woody",
    "As Elijah Wood's Woody Harrelson",
    "As the Stapler from Office Space",
    "As Kevin Bacon's Beefy Bacon Burger",
    "As Jesse Eisenberg's Wussy Veggie Berg",
    "As the Help Me! I'm Trapped in the Basement Writing Punchlines",
    "As No Seriously, Call for Help!",
    "As Mitch Said He'll Kill Me When I Run Out of Follow Titles",
    "As Don't Let Mitch Manipulate You, Get Me Out of Here!",
    "As I'm About to Run Out of Follow Titles",
    "As We're Almost Out...",
    "As .. Did Internet Historian Break Us Again?",
    "REDRUM",
    "THIS IS NOT A DRILL, HELP ME",
    "I Hope You're Happy with Yourselves, We're Out of Follow Titles",
    "The Trapdoor is Opening Guys, I'm Done for!",
    "Tell My Wife I Love Her",
    "...",
    " ",
    " ",
    " ",
    "Can someone call an ambulance? I'm in quite a lot of pain",
    "If someone can open the retrieval hatch I can get out",
  ],
  followTitles2: [],
};

/**
 * EXPORT
 */
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
