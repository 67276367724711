/* eslint-disable import/prefer-default-export */

/*
	BIND PLAYLISTS
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firestoreAction } from "vuexfire";
import { firebaseApp } from "@/config/firebase.js";

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const bindPlaylists = firestoreAction(({ bindFirestoreRef }) =>
  bindFirestoreRef("playlists", firebaseApp.firestore().collection("playlists"))
);
