import { firebase } from "../../../config/firebase";
import generateUUID from "./_generateUUID.util";

/**
 * RUN TASKS
 *
 * Usage: this.$store.dispatch('runTasks', [{ uuid: 1234, ... }]);
 *
 * NOTE: If task has no UUID, it must be an anonymous child subtask so skip library merge.
 * (ad-hoc user soundfx with twitchId as filename, 347234212313.mp3) (rework this)
 */
export default async function runTasks({ dispatch, getters }, tasks = []) {
  if (!Array.isArray(tasks)) {
    console.warn("Oops! `runTasks` payload is expecting array.");
    return null;
  }

  if (!tasks.length) {
    return null;
  }

  const formattedTasks = getAllTasks(getters, tasks);
  return dispatch("activateTasks", formattedTasks);
}

/**
 * GET ALL TASKS (RECURSIVE)
 *
 * In this v1 api, the idea is tasks are passed in via uuid, which matches up to
 * a preset library task. This fills in the rest of the props.
 */
function getAllTasks(getters = {}, tasks = [], parentId = "") {
  const formattedTasks = getFormattedTasks(getters, tasks, parentId);
  const tasksInParallel = getParallelTasks(formattedTasks);

  const allTasks = tasksInParallel.reduce((acc, task) => {
    const childTasks = getAllTasks(getters, task.tasksInParallel, parentId);
    return acc.concat(childTasks);
  }, []);

  return allTasks.concat(formattedTasks);
}

/**
 * GET FORMATTED TASKS (RECEIVING <<)
 */
function getFormattedTasks(getters = {}, tasks = [], parentId = "") {
  return tasks.map((task) => {
    const taskFromLibrary = getters.getTaskFromLibraryByUUID(task.uuid);

    return {
      ...taskFromLibrary,
      // DANGER
      // Remember: task.createdAt will override since it's coming in thru server.
      createdAt: firebase.firestore.Timestamp.now(), // v2
      // DANGER
      ...task, // <-- server `createdAt` will overrride 👍
      data: {
        ...taskFromLibrary.data,
        ...task.data,
      },
      // Without this, 'destroyed' can't be overwritten and Debug will fail to
      // return after destroy. (See components in devtools).
      componentData: {
        ...taskFromLibrary.componentData,
        ...task.componentData,
      },
      // `id` from firebase is un-enumerable, so we need to explicitly add it.
      fbId: task.id ?? false,
      id: task.id ?? generateUUID(), // localId
      ...(parentId && { parentId }),
    };
  });
}

/**
 * GET PARALLEL TASKS
 *
 * 1. "Task Component" must be loaded before parallel tasks can be run (see StageComponent.vue).
 * 2. Don't consider sleeping parallel tasks until awake.
 */
function getParallelTasks(tasks = []) {
  return tasks.filter(
    (task) => task.tasksInParallel.length && !task.componentData.path && !task.delayStart
  );
}
