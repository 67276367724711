import Vue from "vue";

const vueInstance = new Vue();

/**
 * ADD TASKS
 */
const ADD_TASKS = (state, tasks = []) => {
  tasks.forEach((task) => {
    const willPlaySound = task.type === "sound" && !task.canceled && !task.sleeping;

    if (willPlaySound) {
      vueInstance.$playSound(task.data.value, task.data.playOptions, task.id);
    }
    state.tasks.push(task);
  });
};

/**
 * CANCEL TASKS
 */
const CANCEL_TASKS = (state, tasks = []) => {
  tasks.forEach((task) => {
    if (task.type === "sound") {
      vueInstance.$stopSound(task.id);
    }

    clearTimeout(task.durationTimeout);
    clearTimeout(task.delayStartTimeout);
    clearTimeout(task.delayEndTimeout);

    task.timingFunction = null;
    task.canceled = true;
  });
};

/**
 * CLEAR ALL TASKS
 */
const CLEAR_ALL_TASKS = (state) => {
  vueInstance.$stopAllSounds();
  state.tasks = state.tasks.filter((item) => !item.purgeable); // Eg broadcasting will survive a reset.
};

/**
 * DESTROY COMPONENT TASK
 */
const DESTROY_COMPONENT_TASKS = (state, path) => {
  state.tasks
    .filter((task) => task.componentData.path === path && task.canceled)
    .forEach((task) => {
      task.componentData.destroyed = true;
    });
};

/**
 * REMOVE ALL CANCELED TASKS
 * For manual cleanup.
 */
const REMOVE_ALL_CANCELED_TASKS = (state) => {
  state.tasks = state.tasks.filter((item) => !item.canceled);
};

/**
 * REMOVE TASKS - Heads up: this had a lot of past issues.
 * Once tasks have been canceled, eject them from the array after 10secs + `destroyDelay`.
 */
const REMOVE_TASKS = (state, tasks = []) => {
  tasks.forEach((task) => {
    const index = state.tasks.indexOf(task);
    state.tasks.splice(index, 1);

    // LEAVE PAST ATTEMPTS HERE FOR REFERENCE:
    // state.tasks = state.tasks.filter(item => item.id !== target.id); // don't use this - creates new instance
    // state.tasks[index] = null;
    // delete state.tasks[index];
    // Vue.delete(state.tasks, index)
  });
};

/**
 * SET DEFAULT TASKS
 *
 * The page passes "default tasks" that are later injected into `activateTasks` on `Stream Init`.
 * This prevents init from wiping default tasks and requiring a refresh to restore the defaults.
 */
const SET_DEFAULT_TASKS = (state, tasks = []) => {
  state.tasksDefault = tasks;
};

/**
 * SET TASKS LIBRARY
 */
const SET_TASKS_LIBRARY = (state, tasks = []) => {
  state.tasksLibrary = tasks;
};

/**
 * EXPORT
 */
export default {
  ADD_TASKS,
  CANCEL_TASKS,
  CLEAR_ALL_TASKS,
  DESTROY_COMPONENT_TASKS,
  REMOVE_ALL_CANCELED_TASKS,
  REMOVE_TASKS,
  SET_DEFAULT_TASKS,
  SET_TASKS_LIBRARY,
};
