/*
	STATE
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

//------------------------------------------------------------
// STATE
//------------------------------------------------------------

const state = {
	scenesTransitioning: null
}

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
}