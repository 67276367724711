/*
	MUTATIONS
*/
//------------------------------------------------------------
//
//------------------------------------------------------------

const ADD_FOLLOWER = (state, value) => {
  state.sessionFollowers.push(value);
};

const SET_BROADCASTING = (state, value) => {
  state.broadcasting = value;
};

const REMOVE_LAST_FOLLOW_TITLE = (state, value) => {
  state.followTitles.shift();
};

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  ADD_FOLLOWER,
  REMOVE_LAST_FOLLOW_TITLE,
  SET_BROADCASTING,
};
