/* eslint-disable no-param-reassign */

/**
 * NOTES:
 *
 * 'interrupt' only works if calling by sound *filename* outside of task.
 *
 * USAGE:
 *  1. this.$playSound('<filename.ext>', { interrupt: true, volume: 0.4 });
 *  2. using a task with the sound file name as the data.value, and the task id as the instance name
 */

import "latest-createjs/lib/preloadjs/preloadjs";
import "latest-createjs/lib/soundjs/soundjs";

export default {
  install(Vue, options) {
    const activeGlobalSounds = {};

    Vue.prototype.$playSound = (label = "", config = {}, taskId = "") => {
      const playOptions = {
        ...config,
        loop: config.loop ? -1 : 0,
      };

      /**
       * Interrupt only works in manual calls (not via tasks - see usage in notes).
       * It will interrupt any other active sounds with the same filename.
       * Remember: sounds within the task system will cancel at the task level, not here.
       * Sound.js's native implementation of 'interrupt' doesn't work well here. This works better:
       */
      const willInterrupt = activeGlobalSounds[label] && playOptions.interrupt;
      if (willInterrupt) {
        activeGlobalSounds[label].stop();
      }

      /**
       * Bind sound to the task id OR the filename label (manual call - see usage notes).
       */
      const stripLabelExt = label.split(".mp3")[0];
      activeGlobalSounds[taskId || label] = createjs.Sound.play(stripLabelExt, playOptions);
    };

    // STOP SOUND
    // I suspect this wasn't finished. Can we not stop sounds with task id?
    Vue.prototype.$stopSound = (label = "") => {
      const taskId = label.split(".mp3")[0];

      if (activeGlobalSounds[taskId]) {
        activeGlobalSounds[taskId].stop();
        delete activeGlobalSounds[taskId];
      }
    };

    // STOP ALL SOUNDS
    Vue.prototype.$stopAllSounds = () => {
      Object.keys(activeGlobalSounds).forEach((key) => {
        activeGlobalSounds[key].stop();
        delete activeGlobalSounds[key];
      });
    };
  },
};
