/**
 * NOTES:
 *
 * https://vuefire.vuejs.org/vuexfire/getting-started.html#installation
 * https://github.com/firebase/firebase-js-sdk/issues/848
 * https://www.youtube.com/watch?v=831zOI02Q_0&t=332s
 * https://firebase.google.com/docs/web/modular-upgrade?authuser=1
 */

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";

const PROD_CONFIG = {
  apiKey: "AIzaSyDBnJHjDDsPDhtddTBwhBxAQMFD4u_m5Z4",
  authDomain: "themitchinghour-v1-production.firebaseapp.com",
  databaseURL:
    "https://themitchinghour-v1-production-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "themitchinghour-v1-production",
  storageBucket: "themitchinghour-v1-production.appspot.com",
  messagingSenderId: "1075614201805",
  appId: "1:1075614201805:web:bec5eaf98364b9ce79f684",
};

const firebaseApp = firebase.initializeApp(PROD_CONFIG);

export { firebase, firebaseApp };
