<template>
  <div>
    <slot
      :loaded-assets="loadedAssets"
      :total-bytes-loaded="totalBytesLoaded"
      :total-progress-percentage="totalProgressPercentage"
    />
  </div>
</template>

<script>
  /* eslint-disable no-undef */
  export default {
    props: {
      manifest: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        loadQueue: null,
        totalBytesLoaded: 0,
        totalProgressPercentage: 0, // 100%
        loadedAssets: [],
      };
    },
    created() {
      this.init();
    },
    beforeDestroy() {
      this.loadQueue = null;
    },
    methods: {
      init() {
        // Note: preferXHR argument is true.
        this.loadQueue = new createjs.LoadQueue(true);

        // *Required* so not to default to Web Audio.
        createjs.Sound.registerPlugins([createjs.HTMLAudioPlugin]);

        this.loadQueue.installPlugin(createjs.Sound);

        // createjs.Sound.registerSounds(this.manifest);

        // Remember: the asset manifest was created at compile time with Webpack.
        this.loadQueue.loadManifest(this.manifest);
        this.loadQueue.setMaxConnections(20);

        // console.log({ manifesto: this.manifest });

        /**
         * ON FILE LOAD
         */
        this.loadQueue.addEventListener("fileload", (event) => {
          this.loadedAssets.push({ id: event.item.id });
        });

        /**
         * ON FILE PROGRESS
         */
        // this.loadQueue.addEventListener("fileprogress", (event) => {
        //   requestAnimationFrame(() => {
        //     const { loaded, total, item } = event;
        //     const { id } = item;

        //     this.totalProgressPercentage = Math.floor(loaded * 100);
        //   });
        // });

        /**
         * ON PROGRESS (OVERALL %)
         */
        this.loadQueue.addEventListener("progress", (event) => {
          requestAnimationFrame(() => {
            const { loaded, total } = event;

            // Important: so that "100%" will show before removal.
            if (loaded <= total) {
              this.totalProgressPercentage = Math.floor(loaded * 100);
            }
            this.totalBytesLoaded = loaded;
          });
        });

        /**
         * ON COMPLETE
         */
        this.loadQueue.addEventListener("complete", () => {
          requestAnimationFrame(() => {
            this.loadedAssets.push({ id: "All files loaded.", error: "" });
            this.$emit("preload-complete", true);
          });
        });

        /**
         * ON ERROR
         */
        this.loadQueue.addEventListener("error", (event) => {
          requestAnimationFrame(() => {
            this.loadedAssets.push({ id: event.item.id, error: event.text });
          });
        });
      },
    },
  };
</script>
