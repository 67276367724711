/*
	ACTIONS

	Notes:
	- Database reordering: https://stackoverflow.com/questions/55600137/task-list-with-re-ordering-feature-using-firebase-firestore
	- thumbs: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api/2068371#2068371
	- https://stackoverflow.com/questions/54427952/youtube-api-video-dimensions-or-aspect-ratio/54443603
	- https://img.youtube.com/vi/mihGaDLbUgk/maxresdefault.jpg
	- http://img.youtube.com/vi/<YouTube_Video_ID_HERE>/hqdefault.jpg
	- http://img.youtube.com/vi/<YouTube_Video_ID_HERE>/mqdefault.jpg
	- https://www.googleapis.com/youtube/v3/videos?id=7lCDEYXw3mM&key=AIzaSyDB_4ucW_JgxObFdAiGCS0oEbYqpNIYEgI&part=snippet,statistics,player,contentDetails

	PLAYLIST EXAMPLE:
		https://www.youtube.com/playlist?list=PLLCkf4eP3rL0tLiXsS89h4NIHhxMVFnIJ
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { bindPlaylists } from './bindPlaylists.js';
import { clearPlaylist } from './clearPlaylist.js';
import { removeFromPlaylist } from './removeFromPlaylist.js';
import { setActiveVideo } from './setActiveVideo.js';
import { setFlag } from './setFlag.js';
import { setVideoIndex } from './setVideoIndex.js';
import { shufflePlaylist } from './shufflePlaylist.js';
import { updatePlaylist } from './updatePlaylist.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	bindPlaylists,
	clearPlaylist,
	removeFromPlaylist,
	setActiveVideo,
	setFlag,
	setVideoIndex,
	shufflePlaylist,
	updatePlaylist,
}