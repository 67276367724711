/*
	MUTATIONS
*/
//------------------------------------------------------------
// SET DATA
//------------------------------------------------------------

const SET_SCENE_TRANSITION_STATUS = (state, value) => {
	state.scenesTransitioning = value;
}

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	SET_SCENE_TRANSITION_STATUS
}