import actions from "./actions/index";
import getters from "./getters";
import mutations from "./mutations";

/**
 * STATE
 */
const state = {
  clientDevices: {},
  clientDeviceId: "",
  clientsOnline: [], // Possibly move to some chat component in future.
  clientStatus: "", // "unauthorized" | "unsupported" | "loggedOut"
  pushNotifications: {},
  userData: null,
};

/**
 * EXPORT
 */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
