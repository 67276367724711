/**
 * ACTIONS
 */
import { firebaseAction, firestoreAction } from "vuexfire";
import { firebaseApp, firebase } from "@/config/firebase";

/**
 * ADD SOMETHING
 */
// const addSomething = ({ state, commit }, value) => {
//   commit("ADD_SOMETHING", value);
// };

/**
 * BIND COOLDOWNS
 */
const bindCooldowns = firestoreAction(({ bindFirestoreRef }) => {
  const ref = firebaseApp
    .firestore()
    .collection("cooldowns")
    // .where("expiresAt", ">", new Date())
    .orderBy("expiresAt");
  return bindFirestoreRef("cooldowns", ref);
});

/**
 * DELETE BY ID
 */
const deleteById = async ({ state, commit }, docId) => {
  await firebaseApp.firestore().collection("cooldowns").doc(docId).delete();
  console.log("deleted!");
};

/**
 * EXPORT
 */
export default {
  bindCooldowns,
  deleteById,
};
