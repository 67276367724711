import { firebaseAction } from "vuexfire";
import { firebaseApp } from "@/config/firebase";

export default firebaseAction(({ bindFirebaseRef }) => {
  // Only get the first 100 users. Todo: work out pagination.
  const ref = firebaseApp
    .database()
    .ref("presence")
    .limitToLast(100);

  return bindFirebaseRef("clientsOnline", ref);
});
