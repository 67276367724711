import { client } from "tmi.js";

/**
 * GETTERS
 */
const getClientStatus = (state) => state.clientStatus;
const getClientsOnline = (state) => state.clientsOnline;
const getDisplayName = (state) => state.userData?.displayName;
const getUsername = (state) => state.userData?.username;
const getUserData = (state) => state.userData;

// isClientDeviceSleeping relies on this so to avoid showing on init.
const getClientDevice = (state) => {
  return state.clientDevices?.[state.clientDeviceId] || state.clientDevices;
};

const getClientDeviceId = (state) => state.clientDeviceId;

/**
 * EXPORT
 */
export default {
  getClientDevice,
  getClientDeviceId,
  getClientsOnline,
  getDisplayName,
  getUserData,
  getUsername,
  getClientStatus,
};
