/**
 * NOTES:
 *
 * Avoid circular dependency:
 * "commit state changes that indicate the user is no longer logged in,
 * then the App can react to this and handle it accordingly (such as redirecting to login)."
 * https://www.reddit.com/r/vuejs/comments/f4pz8s/using_thisrouterpush_from_within_the_store/
 *
 * router.push("/");
 */

// import router from "@/router/router";
import { firebaseApp } from "@/config/firebase";

/**
 * LOGOUT
 *
 * @returns {promise}
 */
export default async function logout({ dispatch }) {
  return firebaseApp
    .auth()
    .signOut()
    .then(() => {
      dispatch("setClientStatus", "loggedOut");
      // ScreenLock handles the redirection.
    })
    .catch((err) => {
      console.error("Oops! Failed to signOut of Firebase.");
      dispatch("setClientStatus", "loggedOut");
    });
}
