/**
 * NOTES:
 *
 * https://github.com/vuejs-templates/webpack-simple/issues/29
 * https://eslint.org/docs/rules/no-new
 */

import "./assetsManifest";
import Vue from "vue";

// https://github.com/theomessin/vue-chat-scroll/issues/99 -- Not compat with V3
import VueChatScroll from "vue-chat-scroll";

// https://shubhamjain.co/til/vue-shortcuts/ -- Not compat with V3
import VueShortkey from "vue-shortkey";

// https://github.com/MetinSeylan/Vue-Socket.io/issues/316 -- Not compat with V3
// https://github.com/MetinSeylan/Vue-Socket.io/issues/292
import VueSocketIO from "vue-socket.io";
import { io } from "socket.io-client"; // https://github.com/MetinSeylan/Vue-Socket.io/issues/301 -- HOORAY!

// https://github.com/Techassi/vue-youtube-iframe
import VueYouTubeEmbed from "vue-youtube-embed"; // - Not compat with V3
import SoundManager from "./plugins/SoundManager";
import Logger from "./plugins/Logger";

import { firebaseApp } from "@/config/firebase";

import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";

Vue.use(Logger);
Vue.use(SoundManager);
Vue.use(VueChatScroll);
Vue.use(VueYouTubeEmbed);
Vue.use(VueShortkey); // { prevent: ['input', 'textarea'] }

// const socket = io(process.env.VUE_APP_SERVER_ORIGIN, {
//   transports: ["websocket", "polling", "flashsocket"],
// });

const socket = io.connect(process.env.VUE_APP_SERVER_ORIGIN, {
  transports: ["websocket"], // Required for Vite.
});

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socket,
    //connection: process.env.VUE_APP_SERVER_ORIGIN, // Eg. http://localhost:3000 | https://server.themitchinghour.com
  })
);

Vue.config.productionTip = false;
window.debug = process.env.NODE_ENV !== "production";

let app;

firebaseApp.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
