/**
 * SET SOMETHING
 */
const SET_SOMETHING = (state, value) => {
  // state.something = value;
};

/**
 * EXPORT
 */
export default {
  SET_SOMETHING,
};
