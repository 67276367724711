/*
	REMOVE FROM PLAYLIST

	- Take array of indexes, convert to array of video obj references.
	- https://stackoverflow.com/questions/46738599/delete-index-at-array-in-firestore
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firebase, firebaseApp } from '@/config/firebase.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const removeFromPlaylist = ({ dispatch, getters }, payload) => {
	const batch = firebaseApp.firestore().batch();
	const playlistsRef = firebaseApp.firestore().collection('playlists').doc('public');

	// Accept single index or array of indices.
	[...[payload].flat()].forEach(index => {
		const doc = getters.getPlaylist[index];

		// Refund user points if song NOT played and song NOT flagged.
		if (index > getters.getActiveVideoIndex && !doc.flagged) {
			// console.log('Refunding user points.')
			dispatch('chatbot/refundUserPoints', doc, { root: true });
		}

		// Update database.
		batch.update(playlistsRef, {
			"videos": firebase.firestore.FieldValue.arrayRemove(doc)
		});
	});

	batch.commit();
}