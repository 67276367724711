/**
 * GETTERS
 */

const getActiveSceneName = (state, rootState, getters, rootGetters) => {
  return state.activeSceneName;
};

/**
 * GET INPUTS
 */
const getInputs = (state) => {
  return state.inputs;
};

/**
 * GET INPUT BY NAME
 */
const getInputByName = (state) => (name) => {
  return state.inputs.find((input) => input.name === name) || {};
};

/**
 * IS WEB SOCKET CONNECTED
 */
const getIsWebSocketConnected = (state) => {
  return state.isWebSocketConnected;
};

/**
 * GET SOURCE BY NAME
 * Remember: ids are important here as same sources can be across multiple scenes.
 */
const getSourceByName = (state) => (name, parentSceneName) => {
  if (parentSceneName) {
    return (
      state.sources.find(
        (source) => source.name === name && source.parentSceneName === parentSceneName
      ) || {}
    );
  }
  return state.sources.find((source) => source.name === name) || {};
};

/**
 * GET SOURCES BY SCENE NAME
 */
const getSourcesBySceneName = (state) => (sceneName) => {
  return state.sources.filter((source) => source.parentSceneName === sceneName);
};

/**
 * GET SOURCES
 */
const getSources = (state) => {
  return state.sources;
};

/**
 * EXPORT
 */
export default {
  getActiveSceneName,
  getInputByName,
  getInputs,
  getIsWebSocketConnected,
  getSourceByName,
  getSources,
  getSourcesBySceneName,
};
