/**
 * ACTIONS
 */

const setState = ({ dispatch, state, commit }, value) => {
  commit("SET_STATE", value);
};

/**
 * EXPORT
 */
export default {
  setState,
};
