/**
 * GET SHORTHAND
 * eg "mediaPoster/Request+Movie/The+Terminator--300ms--ON"
 */
export default function getShorthand(task = {}) {
  const { data = {}, group = "", duration = 0, canceled = null } = task;
  const { value = "", arg = "" } = data;

  const valueFormatted = value?.replaceAll(" ", "+");
  const argFormatted = arg?.replaceAll(" ", "+");

  let shorthand = `${group}/${valueFormatted}`;
  if (arg) shorthand += `/${argFormatted}`;
  if (duration) shorthand += `--${duration}ms`;

  shorthand += `--${canceled ? "OFF" : "ON"}`;

  return shorthand;
}
