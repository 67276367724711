/**
 * NOTES:
 *
 * This is included in the MitchOS cron job that periodically handles cooldown removal.
 * Permanently removes canceled tasks when safe to do so. This solves ending transitions.
 */

const SAFETY_BUFFER_MILLIS = 5000; // Just incase (if longer transitions..)

/**
 * REMOVE ALL CANCELED TASKS
 */
export default async function removeAllCanceledTasks({ state, commit }) {
  const now = new Date();

  const canceledTasks = state.tasks.reduce((acc, task) => {
    const destroyDelay = Number(task.componentData.destroyDelay + SAFETY_BUFFER_MILLIS);
    const isSafeToRemove = new Date(task.canceled + destroyDelay) < now;

    if (task.canceled && !task.sleeping && isSafeToRemove) {
      acc.push(task);
    }

    return acc;
  }, []);

  if (canceledTasks.length) {
    commit("REMOVE_TASKS", canceledTasks);
  }
}
