/**
 * NOTES:
 * https://github.com/saricden/no-place-like/blob/master/src/lambda/buildCredits.js
 */

const TWITCH_CONFIG = {
  clientID: "t0wzlk40enpqizid5gsw3ijxs5h00l", // <-- Generated by official Twitch dev console.
};

const host = process.env.VUE_APP_SERVER_ORIGIN;

const TWITCH_API = {
  banUser: `${host}/api/v1/twitch/ban-user`,
  getBroadcasterSubscriptions: `${host}/api/v1/twitch/get-subscriptions`,
  getChannelFollowers: `${host}/api/v1/twitch/get-channel-followers`,
  getStreams: `${host}/api/v1/twitch/get-streams`,
  getChatBadges: `${host}/api/v1/twitch/get-chat-badges`,
  getUsers: `${host}/api/v1/twitch/get-users`,
  redirectUri: `${host}/api/v1/auth/login`,
};

export default {
  TWITCH_API,
  TWITCH_CONFIG,
};
