/*
	GETTERS
*/
//------------------------------------------------------------
// GET DATA
//------------------------------------------------------------

const getChatBadges = (state) => {
  return state.twitchBadgeCache.data;
};

const getFollowerCount = (state) => {
  return state.followerCount;
};

const getFollowerGoal = (state) => {
  return state.followerGoal;
};

const getLatestFollowers = (state) => {
  return state.latestFollowers;
};

const getLatestFollower = (state) => {
  return state.latestFollower;
};

const getViewerCount = (state) => {
  return state.viewerCount;
};

const getSubscriberCount = (state) => {
  return state.subscriberCount;
};

const getViewerCountPollingPaused = (state) => {
  return state.viewerCountPollingPaused;
};

// Track expiry of cookie.
// const getTokenCookie = state => {
// 	return state.token;
// }

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  getChatBadges,
  getFollowerCount,
  getFollowerGoal,
  getLatestFollowers,
  getLatestFollower,
  // getTokenCookie,
  getViewerCount,
  getViewerCountPollingPaused,
  getSubscriberCount,
};
