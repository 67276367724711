/*
	ACTIONS
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------
//------------------------------------------------------------
// SET SCENE TRANSITION STATUS
//------------------------------------------------------------

const setSceneTransitionStatus = ({ dispatch, state, commit }, value) => {
    commit('SET_SCENE_TRANSITION_STATUS', value);
}

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	setSceneTransitionStatus
}