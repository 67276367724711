/*
	GETTERS
*/
//------------------------------------------------------------
// GET CURRENT SCENE
// NOTE: must have correct amount of params for rootGetters
// There is a potential bug with watching this property,
// it will trigger multiple times.. target computed name and watch that.
//------------------------------------------------------------

const getCurrentScene = (state, rootState, getters, rootGetters) => {
	return rootGetters['tasks/getActiveTask']('scene');
}

//------------------------------------------------------------
// IS LOBBY? -- consider renaming to getSceneLobby
//------------------------------------------------------------

const isLobby = (state, getters) => {
	return Boolean(!getters.getCurrentScene || // if no scene yet
	               !!getters.getCurrentScene && (
	               	getters.getCurrentScene.data.value === 'Stream Init' ||
	               	getters.getCurrentScene.data.value === 'Stream Starting' ||
	               	getters.getCurrentScene.data.value === 'Stream BRB' ||
	               	getters.getCurrentScene.data.value === 'Stream Ending'));
}

//------------------------------------------------------------
// IS STARTING ENDING ? -- consider renaming to getSceneStartingEnding
//------------------------------------------------------------

const isStartingOrEnding = (state, getters) => {
	// return Boolean(!!getters.isLobby && getters.getCurrentScene.data.value !== 'Stream BRB');

	return !!getters.isLobby &&
			getters.getCurrentScene &&
			getters.getCurrentScene.data.value !== 'Stream BRB';
}

//------------------------------------------------------------
// GET SCENE TRANSITION STATUS
//------------------------------------------------------------

const getSceneTransitionStatus = (state) => {
	return state.scenesTransitioning;
}

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	getCurrentScene,
	getSceneTransitionStatus,
	isLobby,
	isStartingOrEnding
}