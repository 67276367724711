/* eslint-disable no-alert */
/* eslint-disable implicit-arrow-linebreak */

/**
 * NOTES:
 *
 * Remember: logic can be very twisty here, just think in guards.
 *
 * https://github.com/savvyapps/SAVuegram/blob/master/src/router/index.js
 * https://github.com/aofdev/vue-firebase-auth-vuex/blob/master/src/main.js
 * https://css-tricks.com/protecting-vue-routes-with-navigation-guards/
 *
 * SUBDOMAINS: https://medium.com/@apalshah/vue-js-how-to-handle-multiple-subdomains-on-a-single-app-cba9b1f916c4
 * USER ROLES IN VUEX: https://github.com/anthonygore/vue-router-user-roles
 */

import Vue from "vue";
import VueRouter from "vue-router";
import { firebaseApp } from "@/config/firebase";
import Login from "@/views/Login.vue";
import store from "../store/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    //-----------------------------------------------------------------
    // LOGIN
    //-----------------------------------------------------------------
    {
      path: "/",
      name: "login",
      component: Login,
      meta: {
        name: "Login",
      },
      pathToRegexpOptions: { strict: true }, // Enforce trailing slash.
    },
    //-----------------------------------------------------------------
    // HOME (USER PAGE)
    //-----------------------------------------------------------------
    {
      path: "/home",
      alias: ["/user"],
      name: "user",
      component: () => import(/* webpackChunkName: "user" */ "@/views/User/User.vue"),
      meta: {
        allowedRoles: ["authenticated"],
        name: "Home",
      },
    },
    //-----------------------------------------------------------------
    // CHAT POPOUT
    //-----------------------------------------------------------------
    {
      path: "/popout/chat",
      name: "chat",
      component: () => import(/* webpackChunkName: "popout-chat" */ "@/views/Admin/PopoutChat.vue"),
      meta: {
        allowedRoles: ["broadcaster"],
        name: "Chat - TheMitchingHour",
      },
    },
    //-----------------------------------------------------------------
    // ADMIN
    //-----------------------------------------------------------------
    {
      path: "/admin",
      name: "admin",
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Admin.vue"),
      meta: {
        allowedRoles: ["administrator"],
        name: "Admin",
      },
    },
    //-----------------------------------------------------------------
    // BROADCAST
    //-----------------------------------------------------------------
    {
      path: "/all",
      // alias: ["/all"],
      name: "broadcast-all",
      component: () => import(/* webpackChunkName: "broadcast-all" */ "@/views/Broadcast/All.vue"),
      meta: {
        allowedRoles: ["broadcaster"],
        name: "All",
      },
    },
    //-----------------------------------------------------------------
    // BROADCAST -- BACKGROUND
    //-----------------------------------------------------------------
    {
      path: "/background",
      alias: ["/bg"],
      name: "broadcast-background",
      component: () =>
        import(/* webpackChunkName: "broadcast-background" */ "@/views/Broadcast/Background.vue"),
      meta: {
        allowedRoles: ["broadcaster"],
        name: "Background",
      },
    },
    //-----------------------------------------------------------------
    // BROADCAST -- FOREGROUND
    //-----------------------------------------------------------------
    {
      path: "/foreground",
      alias: ["/fg"],
      name: "broadcast-foreground",
      component: () =>
        import(/* webpackChunkName: "broadcast-foreground" */ "@/views/Broadcast/Foreground.vue"),
      meta: {
        allowedRoles: ["broadcaster"],
        name: "Foreground",
      },
    },
    //-----------------------------------------------------------------
    // BROADCAST -- PEER TEST
    //-----------------------------------------------------------------
    {
      path: "/peer-test",
      alias: ["/peer", "/p2p"],
      name: "broadcast-peer-test",
      component: () =>
        import(/* webpackChunkName: "broadcast-peer-test" */ "@/views/Broadcast/PeerTest.vue"),
      meta: {
        allowedRoles: ["authenticated"],
        name: "PeerTest",
      },
    },
    //-----------------------------------------------------------------
    // BROADCAST -- SIMULATION
    //-----------------------------------------------------------------
    {
      path: "/simulation",
      alias: ["/sim"],
      name: "broadcast-simulation",
      component: () =>
        import(/* webpackChunkName: "broadcast-simulation" */ "@/views/Broadcast/Simulation.vue"),
      meta: {
        allowedRoles: ["broadcaster"],
        name: "Simulation",
      },
    },
  ],
});

/**
 * BEFORE EACH
 * https://router.vuejs.org/guide/advanced/meta.html
 */
router.beforeEach(async (to, from, next) => {
  const { currentUser } = firebaseApp.auth();
  const queryParamFirebaseToken = to.query?.t;
  const queryParamRedirectURL = to.query?.r || "/home";
  // const isAuthRequired = to.matched.some((record) => record.meta.isAuthRequired);
  const isLogin = to.matched.some((record) => record.path === "/");

  const isTokenInURL = !!queryParamFirebaseToken;
  const isUserLoggedIn = !!currentUser;
  const isUserApproved = to.params.approved;
  const isUserDenied = to.name === "login" && isUserApproved;

  // AD HOC TO TEST PEER.JS
  if (process.env.NODE_ENV !== "production") {
    if (window.location.hostname !== "localhost") {
      next();
      return true;
    }
  }

  /**
   * NO COOKIES = NO PLAY
   */
  if (!isCookiesEnabled) {
    store.dispatch("user/setClientStatus", "unsupported");
    return false;
  }

  /**
   * 1. IF USER NOT LOGGED IN,
   *    HAS FIREBASE TOKEN VIA URL QUERY STRING,
   *    DO AUTHENTICATE
   */
  if (isTokenInURL) {
    // Edge case: non-existing/hacked paths in redirect param default back to "/".
    const isRedirect404 = router.resolve(queryParamRedirectURL).resolved.matched[0].path === "/";
    const path = isRedirect404 ? "/home" : queryParamRedirectURL;

    firebaseApp
      .auth()
      .signInWithCustomToken(queryParamFirebaseToken)
      .then(() => next(path)) // Go to #3
      .catch((err) => {
        // Eventually replace this with ScreenLock message.
        alert("Oops! Your account has failed to authenticate:", err);
        next({ name: "login", params: { approved: true } }); // Go to #2
      });

    return true;
  }

  /**
   * 2. IF USER WAS DENIED ACCESS
   *    ESCOURT TO LOGIN
   */
  if (isUserDenied) {
    next();
    return false;
  }

  /**
   * 3. IF USER WAS/IS LOGGED IN
   */
  if (isUserLoggedIn) {
    // Is the user approved for this path...
    if (!isUserApproved) {
      const isAccessGranted = await setUserGetAccess(to, from, next);

      if (!isAccessGranted) {
        return false;
      }
    }

    /**
     * PREPARE FINAL DESTINATION
     * Redirect to /home if isLogin.
     * Remember: can't visit /login when "logged in".
     */
    if (isLogin) next({ name: "user", params: { approved: true } });

    // Applies to normal browsing when logged in.
    if (!isLogin) next();

    return true;
  }

  /**
   * 4. NOT LOGGED IN AND ATTEMPTING TO REACH NON-HOME URL
   */
  if (!isLogin && !isUserApproved) {
    next({
      name: "login",
      params: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

/**
 * SET USER GET ACCESS
 */
async function setUserGetAccess(to, from, next) {
  const { roles } = await store.dispatch("user/setUser");
  const isUserAuthorized =
    !to.meta.roles?.length || roles.some((role) => to.meta.roles?.includes(role));

  const isPublicRoute = to.path === "/" || to.path === "/home" || to.path === "/user";

  // ScreenLock will inform then redirect.
  if (!isUserAuthorized && isPublicRoute) {
    store.dispatch("user/setClientStatus", "unauthorized");
    return false;
  }

  // Silent redirect when users poke around paths, /admin etc
  if (!isUserAuthorized) {
    next({ name: "login", params: { approved: true } }); // Go to #2
    return false;
  }

  return true;
}

/**
 * IS COOKIES ENABLED
 *
 * @returns {boolean}
 */
function isCookiesEnabled() {
  let { cookieEnabled } = navigator;
  if (!cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
  }
  return cookieEnabled;
}

/**
 * AFTER EACH
 */
router.afterEach((route) => {
  const suffix = " | The Mitching App (Vue)";
  document.title = route.meta.name + suffix;
});

/**
 * EXPORT
 */
export default router;
