/**
 * NOTES:
 *
 * https://firebase.googleblog.com/2019/11/cloud-firestore-now-supports-in-queries.html
 *
 * Usage: const groups = ['debug', 'youTubePlayer', 'youTubePlaylist', 'scene', 'chat'];
 * this.$store.dispatch('tasks/bindTasks', groups)
 */

import { firestoreAction } from "vuexfire";
import { firebaseApp } from "../../../config/firebase";

/**
 * BIND TASKS
 */
export default firestoreAction(({ bindFirestoreRef }, filterGroups = []) => {
  let tasksRef = firebaseApp.firestore().collection("tasks").orderBy("createdAt", "asc");

  // Specify group namespaces up to 10.
  if (filterGroups.length) {
    console.log("%c :: Filtering binding tasks to: ::", "color: orange;", filterGroups);
    tasksRef = tasksRef.where("group", "in", filterGroups);
  }

  return bindFirestoreRef("tasksRemote", tasksRef);
});
