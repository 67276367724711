/**
 * MUTATIONS
 */

const SET_STATE = (state, value) => {
  Object.entries(value).forEach(([entryKey, entryVal]) => {
    state[entryKey] = entryVal;
  });
};

/**
 * EXPORT
 */
export default {
  SET_STATE,
};
