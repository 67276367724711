<template>
  <div
    id="app"
    :class="appClasses"
  >
    <Preloader
      v-if="isPreloading && manifest"
      :manifest="manifest"
      @preload-complete="onPreloadComplete()"
    >
      <template v-slot:default="slotProps">

        <LoadScreenDefault
          :loaded-assets="slotProps.loadedAssets"
          :total-progress-percentage="slotProps.totalProgressPercentage"
        />

      </template>
    </Preloader>

    <router-view
      :preload-complete="!isPreloading"
      @request-preloader="onPreload($event)"
    />

    <ScreenLock />

  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import LoadScreenDefault from "./components/LoadScreens/Default.vue";
  import Preloader from "./components/Preloader.vue";
  import ScreenLock from "./components/ScreenLock.vue";

  export default {
    components: {
      LoadScreenDefault,
      Preloader,
      ScreenLock,
    },
    data() {
      return {
        manifest: null,
        isPreloading: true,
      };
    },
    computed: {
      ...mapGetters({
        appSettings: "global/getAppSettings",
      }),
      isDownForMaintenance() {
        return this.appSettings?.isDownForMaintenance;
      },
      appClasses() {
        return {
          // set on main.js (not production)
          "is-dev": window.debug || window.location.pathname.includes("/admin"),
          "is-admin": window.location.pathname.includes("/admin"),
        };
      },
    },
    watch: {
      isDownForMaintenance: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.$store.dispatch("user/setClientStatus", "maintenance");
          }
        },
      },
    },
    created() {
      this.$store.dispatch("global/bindAppSettings");
    },
    methods: {
      onPreload(event) {
        this.manifest = event.manifest;
        this.isPreloading = true; // temporaily disabling
      },
      onPreloadComplete() {
        this.isPreloading = false;

        // Remove default styling set on HTML index.
        this.$nextTick(() => {
          document.documentElement.style.background = "transparent";
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "~@/assets/scss/bootstrap/bootstrap";
  @import "~@/assets/scss/app/buttons";
  @import "~@/assets/scss/app/fonts";
  @import "~@/assets/scss/app/scrollbars";
  @import "~@/assets/scss/liquidvisual/components/custom";
  @import "~animate.css/animate.css";

  //-----------------------------------------------------------------
  // BROWSER
  //-----------------------------------------------------------------

  html {
    overflow: hidden; // [LOCKED] - Stops Chrome touchpad wobble.
    color: white;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: antialised;
  }

  ::selection {
    background: lighten($primary, 10%);
    color: black;
    text-shadow: none;
  }

  //-----------------------------------------------------------------
  // APP
  //-----------------------------------------------------------------

  #app {
    position: fixed; // [LOCKED] - Prevents touch jumping.
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #app:not(.is-admin) .show-for-debug {
    display: none !important;
  }

  #app .show-for-admin {
    display: block !important;
  }

  //-----------------------------------------------------------------
  // HELPERS
  //-----------------------------------------------------------------

  .force-gpu {
    transform: translateZ(0);
    will-change: transform;
  }
</style>
