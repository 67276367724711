/**
 * NOTES:
 *
 * vuex-persisted-state: https://github.com/robinvdvleuten/vuex-persistedstate
 * cleaner modules: https://dev.to/simo_benhida/tip---import-vuejs-modules-at-once-58dl
 * https://github.com/posva/vuexfire/issues/125 -- module vuefire
 * https://vuefire.vuejs.org/vuexfire/getting-started.html#installation
 *
 * In order to use Vuexfire, you must add the mutations exported by
 * the package at the root of your store and only there.
 */

import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import createPersistedState from "vuex-persistedstate";

import chat from "./chat";
import cooldowns from "./cooldowns";
import global from "./global";
import obs from "./obs";
import scenes from "./scenes";
import tasks from "./tasks";
import twitch from "./twitch";
import user from "./user";
import youtube from "./youtube";

Vue.use(Vuex);

/**
 * EXPORT
 */
export default new Vuex.Store({
  mutations: {
    ...vuexfireMutations, // Required.
  },
  modules: {
    chat,
    cooldowns,
    global,
    obs,
    scenes,
    tasks,
    twitch,
    user,
    youtube,
  },
  plugins: [
    createPersistedState({
      key: "TheMitchingHour",
      paths: [
        "global.followTitles",
        "global.sessionFollowers",
        "obs.isWebSocketConnected",
        "obs.serverIP",
        "obs.serverPassword",
        "obs.serverPort",
        "twitch.twitchBadgeCache", // !cn will refresh - saves requests during dev.
      ],
    }),
  ],
  // 02.02.18 - 'mutations outside' error when dev tools time travelling.
  strict: false, //window.debug,
});
