/* eslint-disable radix */

/*
    ACTIONS

    Notes:
        * https://stackoverflow.com/questions/34558264/fetch-api-with-cookie
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import axios from "axios";
import twitchConfig from "@/config/twitch";
import { firebase, firebaseApp } from "@/config/firebase";

//------------------------------------------------------------
// VARIABLES
//------------------------------------------------------------

const {
  getChatBadges,
  getStreams,
  getUsers,
  getChannelFollowers,
  getBroadcasterSubscriptions,
} = twitchConfig.TWITCH_API;

const followBotCount = 0; // incase we get botted
let followDataOnce = null;

//------------------------------------------------------------
// FETCH DATA (FOLLOWERS AND VIEWERS)
// https://www.andreasreiterer.at/single-result-with-promise-all/
//
// NOTES:
// https://discuss.dev.twitch.tv/t/get-channel-id-by-user-id-or-display-name-api-v5/12362
// The app access tokens are mainly relevant for the new Helix API, as with a client id only
// you’re limited to 30 requests a minute where as if you use an OAuth token you can make 120
// requests per minute.The app access token is just a way to have an OAuth token to use for
// endpoints that require no authentication.
//------------------------------------------------------------

const fetchTwitchData = ({ dispatch, state, commit }) => {
  const multiFetch = Promise.all([dispatch("fetchStream"), dispatch("fetchChannelFollowers")]);

  multiFetch.then((responses) => {
    try {
      const viewerCount = parseInt(responses[0]?.viewer_count) || 0;
      const followerCount = parseInt(responses[1]?.total - followBotCount) || 0;
      const latestFollowers = responses[1]?.data;

      // console.log({ responses });

      // Set viewers 'outside' of polling.
      if (viewerCount !== -1) {
        dispatch("setViewerCount", viewerCount);
      }

      // refactor and rethink this -- latest should be a subscriber
      // only do this once - later replace this so viewers is only polling. Build in pause functionality so raids can add to counter.
      if (!followDataOnce) {
        followDataOnce = true;

        if (
          Number.isInteger(followerCount) &&
          followerCount !== state.followerCount &&
          followerCount > 0
        ) {
          // quick fix for no data
          commit("SET_FOLLOWER_COUNT", followerCount);
          commit("SET_LATEST_FOLLOWERS", latestFollowers);
        }
      }
    } catch (err) {
      console.warn("Oops! There was a problem with the server:", err);
    }
  });
};

//------------------------------------------------------------
// FETCH GLOBAL CHAT BADGES
// api can get multiple users but this gets just one. Sockets will do the rest.
//------------------------------------------------------------

async function fetchGlobalChatBadges({ dispatch }, query = "") {
  return fetch(`${getChatBadges}?q=${query}`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data) {
        dispatch("setChatBadges", {
          channel: "global",
          data,
        });
      } else {
        console.warn("Hmm. There were no `badge_sets` available.");
      }
    })
    .catch((err) => {
      console.error("Oops! There was an issue fetching global badges:", err);
    });
}

//------------------------------------------------------------
// FETCH STREAM - MUST BE LIVE TO WORK!!
//------------------------------------------------------------

async function fetchStream({ dispatch }, query = "") {
  const url = `${getStreams}?q=${query || firebaseApp.auth().currentUser.uid}`;

  try {
    return fetch(url, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => data?.data?.[0] ?? data)
      .catch((err) => err.message);
  } catch (err) {
    console.log("%c Error with fetchStream!", "color: magenta", err.message);
    return [];
  }
}

//------------------------------------------------------------
// FETCH USER
// This is used by Media Poster. Obsolete once chatbot handled.
//------------------------------------------------------------

const fetchUser = ({}, query = "") => {
  return fetch(getUsers + `?q=${query || firebaseApp.auth().currentUser.uid}`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((data) => (data.data && data.data[0]) || data) // api can get multiple users but this gets just one.
    .catch((err) => err.message);
};

//------------------------------------------------------------
// FETCH CHANNEL FOLLOWERS (Eg. also Shoutouts)
//------------------------------------------------------------

async function fetchChannelFollowers({}, query = "") {
  const userId = firebaseApp.auth().currentUser.uid;
  const url = `${getChannelFollowers}?q=${query || userId}`;

  return axios({
    url,
    method: "GET",
    params: {
      // broadcaster_id: 666
    },
    withCredentials: true,
  })
    .then((data) => data?.data)
    .catch((error) => {
      console.log("Oops! Error with fetchSubscribers:", error);
      return [];
    });
}

//------------------------------------------------------------
// FETCH SUBSCRIBERS (UGH - it should be FETCH AND SET - shit old code)
//------------------------------------------------------------

async function fetchSubscribers({ dispatch }) {
  return axios({
    url: getBroadcasterSubscriptions,
    method: "GET",
    params: {
      // broadcaster_id: 666
    },
    withCredentials: true,
  })
    .then((data) => {
      const total = data?.data?.total ?? 0;
      const subscriberCount = total - followBotCount || 0; // prevent negative numbers (and/or bots).

      // console.log(data.data, { subscriberCount });
      dispatch("setSubscriberCount", subscriberCount);
      return data?.data;
    })
    .catch((error) => {
      console.log("Oops! Error with fetchSubscribers:", error);
      return [];
    });
}

//------------------------------------------------------------
// SET CHAT BADGES
//------------------------------------------------------------

const setChatBadges = ({ commit }, payload) => {
  commit("SET_CHAT_BADGES", payload);
};

//------------------------------------------------------------
// SET FOLLOWER COUNT
// Hmm, on revisiting this:
//
// 1. Increments follower count by one on each Follow alert.
// 2. Updates follower count each 6 second routine?
//------------------------------------------------------------

const setFollowerCount = ({ state, commit }, value = null) => {
  // If no value passed, increment by one.
  commit("SET_FOLLOWER_COUNT", value ?? state.followerCount + 1);
};

//------------------------------------------------------------
// SET SUBSCRIBER COUNT
//------------------------------------------------------------

const setSubscriberCount = ({ state, commit }, value = null) => {
  commit("SET_SUBSCRIBER_COUNT", value ?? state.subscriberCount + 1);
};

//------------------------------------------------------------
// ADD SUBSCRIBER TOTAL
//------------------------------------------------------------

const addSubscriberTotal = ({ state, commit }, value = 1) => {
  commit("SET_SUBSCRIBER_COUNT", state.subscriberCount + value);
};

//------------------------------------------------------------
// SET VIEWER COUNT
//------------------------------------------------------------

const setViewerCount = ({ state, commit }, viewerCount) => {
  if (Number.isInteger(viewerCount) && viewerCount !== state.viewerCount) {
    // ?
    commit("SET_VIEWER_COUNT", viewerCount);
  }
};

//------------------------------------------------------------
// PAUSE VIEWER COUNT POLLING
// Pauses on mount of Follow alert, each alert increments its own.
// Polling resumes after 5 mins to correct any inconsistencies.
//------------------------------------------------------------

const pauseViewerCountPolling = ({ commit }) => {
  commit("PAUSE_VIEWER_COUNT_POLLING", true);

  const FIVE_MINUTE_COOLDOWN = 5 * 60 * 1000;

  // Resume poller after the raid cools off.
  setTimeout(() => commit("PAUSE_VIEWER_COUNT_POLLING", false), FIVE_MINUTE_COOLDOWN);
};

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  addSubscriberTotal,
  fetchChannelFollowers,
  fetchGlobalChatBadges,
  fetchStream,
  fetchSubscribers,
  fetchTwitchData,
  fetchUser,
  pauseViewerCountPolling,
  setChatBadges,
  setFollowerCount,
  setSubscriberCount,
  setViewerCount,
};
