import { firestoreAction } from "vuexfire";
import { firebaseApp } from "@/config/firebase";

// const addFollower = ({ state, commit }, value) => {
// 	commit('ADD_FOLLOWER', value);
// }

/**
 * BIND ACTIVE USERS
 */
const bindActiveUsers = firestoreAction(({ bindFirestoreRef }) => {
  return bindFirestoreRef(
    "activeUsers",
    firebaseApp.firestore().collection("users").where("isActive", "==", true)
  );
});

/**
 * EXPORT
 */
export default {
  bindActiveUsers,
};
