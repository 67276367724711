import { firebase } from "@/config/firebase";
import getCurrentWeekId from "@/utils/getCurrentWeekId.util";

export default {
  install(Vue, options) {
    Vue.prototype.$log = async ({
      data = null,
      message = "No log",
      logToConsole = false,
      type = "info",
    }) => {
      if (logToConsole) {
        console.log(message, data);
      }

      if (message.toLowerCase().includes("error")) {
        type = "error";
      }

      const docId = getCurrentWeekId();

      const logData = {
        // count: firebase.firestore.FieldValue.increment(1), need to upgrade firebase for counting
        logs: firebase.firestore.FieldValue.arrayUnion({
          createdAt: Date.now(),
          data: data ? JSON.stringify(data) : null,
          id: crypto.randomUUID(),
          location: window.location.href,
          message,
          type,
        }),
      };

      try {
        await firebase
          .firestore()
          .collection("logs")
          .doc(docId)
          .set(logData, { merge: true });

        return [true, null];
      } catch (error) {
        return [null, error];
      }
    };
  },
};
