/*
	SET VIDEO INDEX
	https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another

	* Keep in mind that this function deals with actual array indexes
	  but the UI exposes indices starting from 1 (not zero).
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firebaseApp } from '@/config/firebase.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const setVideoIndex = ({ getters }, payload) => {
	const playlist = getters.getPlaylist;
	const fromIndex = parseInt(payload.fromIndex);
	const activeIndex = getters.getActiveVideoIndex;
	const isToUndefined = payload.toIndex === undefined || Number.isNaN(payload.toIndex);
	let toIndex = isToUndefined ? activeIndex : parseInt(payload.toIndex);
	const isFuture = isToUndefined && fromIndex >= activeIndex;
	if (isFuture) toIndex += 1;

	const playlistClone = arrayMove(playlist, fromIndex, toIndex);

	firebaseApp.firestore().collection('playlists').doc('public')
	.update({
		videos: playlistClone
	});

}

function arrayMove(array, from, to) {
	const clonedArray = array.slice();
    clonedArray.splice(to, 0, clonedArray.splice(from, 1)[0]);
    return clonedArray.filter(item => item !== undefined);
};