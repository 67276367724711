import { firestoreAction } from "vuexfire";
import { firebaseApp } from "@/config/firebase";

/**
 * BIND USER
 */
export default firestoreAction(({ bindFirestoreRef }, uid) => {
  const userRef = firebaseApp
    .firestore()
    .collection("users")
    .doc(uid);

  return bindFirestoreRef("userData", userRef);
});
