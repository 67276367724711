import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekOfYear);

/**
 * GET CURRENT WEEK ID
 * Used for generating document ids that change weekly.
 */
export default function getCurrentWeekId() {
  const date = dayjs();
  const dateYear = date.format("YYYY");
  const dateWeek = date.week();
  const id = `week-${dateWeek}-${dateYear}`; // Eg. week-16-2023

  return id;
}
