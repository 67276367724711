/**
 * GETTERS
 */

const getAppSettings = (state) => state.appSettings;
const getBroadcaster = (state) => state.broadcaster;
const getBroadcasting = (state) => state.broadcasting;
const getFollowTitles = (state) => state.followTitles;
const getFollowTitles2 = (state) => state.followTitles2?.titles || [];
const getServerLogs = (state) => state.serverLogs;
const getSessionFollowers = (state) => state.sessionFollowers;

/**
 * EXPORT
 */
export default {
  getAppSettings,
  getBroadcaster,
  getBroadcasting,
  getFollowTitles,
  getFollowTitles2,
  getServerLogs,
  getSessionFollowers,
};
