import actions from "./actions/index";
import getters from "./getters";
import mutations from "./mutations";

/**
 * STATE
 */
const state = {
  tasks: [],
  tasksDefault: [],
  tasksLibrary: [],
  tasksRemote: [],
};

/**
 * EXPORT
 */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
