/* eslint-disable brace-style */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */

import { firebase, firebaseApp } from "@/config/firebase";

/**
 * SET USER PRESENCE
 */
export default async function setUserPresence({ dispatch, getters }, userData) {
  const { displayName, profileImageURL, roles, uid, username } = userData;
  const presenceRef = firebaseApp.database().ref(`/presence/${uid}`);
  const isAdmin = getters.getUserData?.roles.includes("administrator");

  const onlineState = {
    displayName,
    isLocked: false,
    lastActiveAt: false, // If false then would default Date.now()
    profileImageURL,
    stats: {
      browser: getBrowser(),
      entryPath: window.location.pathname,
      isMobile: /Mobi/i.test(window.navigator.userAgent),
      screen: `${window.screen.width}x${window.screen.height}`, // Eg. monitor size
      viewport: `${window.innerWidth}x${window.innerHeight}`,
    },
    roles, // for badges?
    status: "online",
    username,
  };

  /**
   * LOCK ALL EXISTING CLIENT DEVICES
   * (Admins can have multiple devices)
   */
  await presenceRef.child("devices").once("value", (snapshot) => {
    if (snapshot.exists()) {
      const clients = snapshot.val();
      const isUserAllowedMultiDevices = isAdmin;

      Object.keys(clients).forEach((device) => {
        const deviceRef = presenceRef.child("devices").child(device);

        if (!isUserAllowedMultiDevices) {
          deviceRef.update({ isLocked: true });
          // deviceRef.remove(); //.set("deactivated");
        }
      });
    }
  });

  /**
   * PUSH CURRENT CLIENT DEVICE
   */
  const currentDeviceRef = await presenceRef.child("devices").push(onlineState);

  /**
   * Set as AWAY when tab closes or minimized.
   */
  const setAway = () =>
    currentDeviceRef.update({
      lastActiveAt: document.hidden ? firebase.database.ServerValue.TIMESTAMP : false,
      status: document.hidden ? "away" : "online",
    });
  document.addEventListener("visibilitychange", setAway);

  /**
   * ON DISCONNECT - REMOVE IT
   */
  currentDeviceRef.onDisconnect().remove();

  /**
   * DETECT CHANGES
   */
  currentDeviceRef.on("value", (snapshot) => {
    const deviceId = snapshot.key;

    if (deviceId) {
      dispatch("setClientDeviceId", deviceId);
    }
  });
}

/**
 * GET BROWSER
 *
 * @returns {string} Browser string
 */
function getBrowser() {
  const test = (regexp) => regexp.test(window.navigator.userAgent);

  switch (true) {
    case test(/edg/i):
      return "Edge";
    case test(/opr/i) && (!!window.opr || !!window.opera):
      return "Opera";
    case test(/chrome/i) && !!window.chrome:
      return "Chrome";
    case test(/trident/i):
      return "IE";
    case test(/firefox/i):
      return "Firefox";
    case test(/safari/i):
      return "Safari";
    default:
      return "Other";
  }
}
