/* eslint-disable implicit-arrow-linebreak */

/**
 * BASE GETTERS
 */
const getAllTasks = (state) => state.tasks;
const getRemoteTasks = (state) => state.tasksRemote;
const getTasksLibrary = (state) => state.tasksLibrary;

/**
 * GET TASK BY ID (LOCAL)
 */
const getTaskById = (state, getters) => (id) => getters.getAllTasks.find((task) => task.id === id);

/**
 * GET TASK FROM LIBRARY BY UUID
 */
const getTaskFromLibraryByUUID = (state, getters) => (uuid) =>
  getters.getTasksLibrary.find((task) => task.uuid === uuid);

/**
 * GET ACTIVE TASKS (NON-SLEEPING)
 *
 * Get active task array by passing GROUP and/or ACTION.
 * Otherwise return ALL *active* tasks.
 */
const getActiveTasks =
  (state, getters) =>
  (group = "", action = "", isStrict = true) =>
    getters.getAllTasks.filter(
      (task) =>
        (group ? task.group.toLowerCase() === group.toLowerCase() : true) &&
        (action ? task.data.value.toLowerCase() === action.toLowerCase() : true) &&
        isStrict &&
        !task.canceled &&
        !task.sleeping
    );

/**
 * GET ACTIVE TASK (NON-SLEEPING)
 */
const getActiveTask = (state, getters) => (group, action) =>
  getters.getActiveTasks(group, action).slice(-1)[0];

/**
 * GET ACTIVE TASK BACKDROPS
 */
const getActiveTaskBackdrops = (state, getters) => {
  return getters.getAllTasks.filter(
    (item) => item.type.toLowerCase() === "backdrop" && !item.canceled && !item.sleeping
  );
};

/**
 * GET ACTIVE TASK EFFECTS
 */
const getActiveTaskEffects = (state, getters) => {
  return getters.getAllTasks.filter(
    (item) => item.type.toLowerCase() === "effect" && !item.canceled && !item.sleeping
  );
};

/**
 * GET ACTIVE TASK PROGRAMS
 */
const getActiveTaskPrograms = (state, getters) => {
  return getters.getAllTasks.filter(
    (item) =>
      item.type.toLowerCase() === "program" &&
      item.data.value.toLowerCase() === "run" &&
      !item.canceled &&
      !item.sleeping
  );
};

/**
 * GET ACTIVE TASK SCENES
 */
const getActiveTaskScenes = (state, getters) => {
  return getters.getAllTasks.filter(
    (item) => item.type.toLowerCase() === "scene" && !item.canceled && !item.sleeping
  );
};

/**
 * GET ACTIVE TASK SOUNDS
 */
const getActiveTaskSounds = (state, getters) => {
  return getters.getAllTasks.filter(
    (item) => item.type.toLowerCase() === "sound" && !item.canceled && !item.sleeping
  );
};

/**
 * GET GLOBAL HOTKEYS
 * taskGroupBindings scope tasks to specific groups (namespaces).
 */
const getGlobalHotkeys =
  (state, getters) =>
  (taskGroupBindings = []) =>
    getters.getTasksLibrary.reduce((acc, item) => {
      if (item.hotkeyGlobal.length) {
        if (
          !taskGroupBindings.length ||
          (taskGroupBindings.length && taskGroupBindings.includes(item.group))
        ) {
          // console.log('includes group', taskGroupBindings, taskGroupBindings.includes(item.group), item.group)
          acc[item.uuid] = item.hotkeyGlobal;
        }
      }
      return acc;
    }, {});

/**
 * EXPORT
 */
export default {
  getActiveTask,
  getActiveTaskBackdrops,
  getActiveTaskEffects,
  getActiveTaskPrograms,
  getActiveTasks,
  getActiveTaskScenes,
  getActiveTaskSounds,
  getAllTasks,
  getGlobalHotkeys,
  getRemoteTasks,
  getTaskFromLibraryByUUID,
  getTaskById,
  getTasksLibrary,
};
