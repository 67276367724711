/*
	UPDATE PLAYLIST
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firebaseApp } from '@/config/firebase.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const updatePlaylist = ({ getters }, payload) => {

	console.log('updating playlist')

	// Make copy of array.
	const playlistClone = getters.getPlaylist.slice();

	// Update database.
	firebaseApp.firestore()
	.collection('playlists')
	.doc('public')
	.update({
		videos: playlistClone
	});
}