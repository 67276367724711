/**
 * CANCEL TASK BY ID
 */
export default function cancelTasksById({ state, dispatch }, ids = []) {
  const targets = ids.reduce((acc, id) => {
    const task = state.tasks.find((stateTask) => stateTask.id === id);

    if (task) {
      acc.push(task);
    }
    return acc;
  }, []);

  dispatch("cancelTasks", targets);
}
