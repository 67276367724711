import { firebaseApp } from "../../config/firebase";

/**
 * DELETE TASKS
 */
export default async function deleteTasks(tasks = []) {
  const batch = firebaseApp.firestore().batch();
  const tasksRef = firebaseApp.firestore().collection("tasks");

  tasks
    .filter((task) => task.fbId)
    .forEach((task) => {
      batch.delete(tasksRef.doc(task.fbId));
    });

  return batch.commit().catch((err) => console.error("Oops! `deleteTasks` failed:", err));
}
