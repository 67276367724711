/* eslint-disable import/prefer-default-export */

/**
 * NOTES:
 *
 * firebase.firestore.FieldValue.serverTimestamp()
 *
 * POTENTIAL ISSUE: tasks are parsed locally by their `createdAt` timestamp,
 * so parallel tasks won't come through - only the most recent.
 */

import { firebase, firebaseApp } from "../../../config/firebase";
import getShorthand from "./_getShorthand.util";

/**
 * RUN TASKS REMOTELY (FIRESTORE)
 */
export default async function runTasksRemotely({ getters }, tasks = []) {
  const batch = firebaseApp.firestore().batch();
  const docRef = firebaseApp.firestore().collection("tasks").doc();

  tasks.forEach((task) => {
    const taskFromLibrary = getters.getTaskFromLibraryByUUID(task.uuid);
    const data = getFormattedTask(task, taskFromLibrary);

    batch.set(docRef, data);
  });

  return batch.commit().catch((err) => err);
}

/**
 * GET FORMATTED TASK (SENDING >>)
 */
function getFormattedTask(task = {}, taskFromLibrary = {}) {
  return {
    ...taskFromLibrary,
    ...task,
    data: {
      ...taskFromLibrary.data,
      ...task.data,
    },
    // Without this, 'destroyed' can't be overwritten and Debug will fail to
    // return after destroy. (See components in devtools).
    componentData: {
      ...taskFromLibrary.componentData,
      ...task.componentData,
    },

    // Remember: Admin Console hits `setTasks` on the server (TMS).
    createdAt: firebase.firestore.Timestamp.now(),
    __shorthand: getShorthand(taskFromLibrary),
  };
}
