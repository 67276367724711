/**
 * SET CLIENT DEVICE ID
 */
const SET_CLIENT_DEVICE_ID = (state, value) => {
  state.clientDeviceId = value;
};

/**
 * SET CLIENT STATUS
 */
const SET_CLIENT_STATUS = (state, value) => {
  state.clientStatus = value;
};

/**
 * EXPORT
 */
export default {
  SET_CLIENT_DEVICE_ID,
  SET_CLIENT_STATUS,
};
