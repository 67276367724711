/**
 * GET TASK MECHANICS
 */
export default function getTaskMechanics(task = {}) {
  const isInit = task.group === "scene" && task.data.value === "Stream Init";
  const isInitExplicitReset = isInit && task.id !== "666";
  const isOverrideAndReplace = !task.stackable && !task.cancelable;
  const isOverrideAndReplaceAndToggle = !task.stackable && task.cancelable;
  const isStack = task.stackable && !task.cancelable;
  const isStackAndToggle = task.stackable && task.cancelable;

  const isTemporal = !!(
    task.delayStart ||
    task.delayEnd ||
    task.duration ||
    task.tasksOnComplete?.length
  );

  const mechanics = {
    isInit,
    isInitExplicitReset,
    isOverrideAndReplace,
    isOverrideAndReplaceAndToggle,
    isStack,
    isStackAndToggle,
    isTemporal,
  };

  // console.log({ mechanics, taskGroup: task.group, taskValue: task.data?.value });

  return mechanics;
}
