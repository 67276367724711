<template>
  <div class="app-preloader-parent">

    <!-- PROGRESS BOX -->
    <div class="progress-box">

      <!-- PROGRESS PERC (0%) -->
      <div class="progress-perc">
        {{ totalProgressPercentage }}
      </div>

      <!-- PROGRESS BAR -->
      <div class="progress-bar mb-3">
        <div
          class="progress-bar-fill"
          :style="{ transform: 'scaleX('+ progressXScale +')'}"
        >
        </div>
      </div>

      <!-- LOADED ASSETS -->
      <div
        class="loaded-assets"
        v-chat-scroll
      >
        <div
          v-for="item in loadedAssets"
          :key="item.id"
        >
          <code
            v-if="item.error"
            class="error"
          >{{ item.error }}</code>
          <code>{{ item.id }}</code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      totalProgressPercentage: {
        type: Number,
        required: true,
      },
      loadedAssets: {
        type: Array, // files
        required: true,
      },
    },
    computed: {
      progressXScale() {
        return (this.totalProgressPercentage / 100) * 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-preloader-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black; // needs to match index.html
    z-index: 9999;
    // -webkit-backdrop-filter: blur(10px); // doesn't work in Chrome
  }

  .progress-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 180px;
    // height: 150px;
    // border: 1px solid white;
  }

  .loaded-assets {
    position: fixed;
    top: 0;
    left: 0;
    width: rem-calc(600);
    height: 100%;
    padding: rem-calc(20);
    border-right: 1px solid lighten(black, 20%);

    font-size: rem-calc(14);
    font-weight: 600;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;

    code {
      color: $primary;
    }

    code.error {
      color: #ec1225;
    }
  }

  .progress-perc {
    margin-bottom: rem-calc(20);
    font-family: "Avenir Next Condensed";
    font-size: rem-calc(45);
    font-weight: 500;
    line-height: 1;
    color: $primary;
    filter: blur(0.5px); // score
  }

  .progress-bar {
    width: 100%;
    height: 4px;
    box-shadow: 0px 0px 10px darken($primary, 40%);
    background: darken($primary, 40%);
  }

  .progress-bar-fill {
    width: 100%;
    height: 4px;
    box-shadow: 0px 0px 6px $primary;
    background: $primary;
    transform-origin: left;
    filter: blur(0.4px); // score
    transition: transform 100ms linear;
  }
</style>
