/*
	MUTATIONS
*/
//------------------------------------------------------------
// REMOVE FROM PLAYLIST
// https://stackoverflow.com/questions/9425009/remove-multiple-elements-from-array-in-javascript-jquery
//------------------------------------------------------------

// const REMOVE_FROM_PLAYLIST = (state, targets) => {
	// targets.forEach(item => {
	// 	const index1 = state.playlist.indexOf(item);
	// 	if (index1 !== -1) state.playlist.splice(index1, 1);

	// 	const index2 = state.playlistShuffled.indexOf(item);
	// 	if (index2 !== -1) state.playlistShuffled.splice(index2, 1);
	// });
// }

//------------------------------------------------------------
// SHUFFLE PLAYLIST
//------------------------------------------------------------

const SHUFFLE_PLAYLIST = (state, payload) => {
	state.playlistShuffled = payload;
}

const SET_PLAYLIST = (state, payload) => {
	state.playlists[0].videos = payload;
}

const SET_PLAYLIST_SELECTED = (state, value) => {
	state.playlistSelected = value;
}

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
	// REMOVE_FROM_PLAYLIST,
	SET_PLAYLIST,
	SET_PLAYLIST_SELECTED,
	SHUFFLE_PLAYLIST
}