import activateTasks from "./activateTasks";
import bindTasks from "./bindTasks";
import cancelTasks from "./cancelTasks";
import cancelTasksById from "./cancelTasksById";
import destroyComponentTasks from "./destroyComponentTasks";
import removeAllCanceledTasks from "./removeAllCanceledTasks";
import runTasks from "./runTasks";
import runTasksRemotely from "./runTasksRemotely";
import setTimingFunctions from "./setTaskTimingFunction";

/**
 * EXPORT
 */
export default {
  activateTasks,
  bindTasks,
  cancelTasks,
  cancelTasksById,
  destroyComponentTasks,
  runTasks,
  removeAllCanceledTasks,
  runTasksRemotely,
  setTimingFunctions,
};
