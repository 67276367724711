/*
	GETTERS
*/
//------------------------------------------------------------
// GET ACTIVE VIDEO
//------------------------------------------------------------

const getActiveVideo = (state, getters) => {
  return getters.getPlaylist.find((item) => item.playerVars.active) || getters.getPlaylist[0];
};

//------------------------------------------------------------
// GET ACTIVE VIDEO INDEX
//------------------------------------------------------------

const getActiveVideoIndex = (state, getters) => {
  const activeIndex = getters.getPlaylist.findIndex((item) => item.playerVars.active);
  return activeIndex > -1 ? activeIndex : 0;
};

//------------------------------------------------------------
// GET PLAYLISTS
//------------------------------------------------------------

const getPlaylists = (state) => {
  return state.playlists.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};

//------------------------------------------------------------
// GET (PUBLIC) PLAYLIST
//------------------------------------------------------------

const getPlaylist = (state, getters) => {
  // return state.playlistShuffled.length ? state.playlistShuffled : state.playlist;
  return (state.playlists[0] && state.playlists[0].videos) || [];
  // return getters.getPlaylists[getters.getPlaylistSelected]?.videos || [];
};

//------------------------------------------------------------
// GET PLAYLIST PRESETS
//------------------------------------------------------------

const getPlaylistPresets = (state) => state.playlistPresets;

//------------------------------------------------------------
// GET PLAYLIST SELECTED
//------------------------------------------------------------

const getPlaylistSelected = (state) => state.playlistSelected;

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  getActiveVideo,
  getActiveVideoIndex,
  getPlaylists,
  getPlaylist,
  getPlaylistPresets,
  getPlaylistSelected,
};
