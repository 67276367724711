/**
 * DO SOMETHING
 */
const DO_SOMETHING = (state, value) => {};

/**
 * EXPORT
 */
export default {
  DO_SOMETHING,
};
