import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

/**
 * STATE
 */
const state = {
  activeUsers: [],
};

/**
 * EXPORT
 */
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
