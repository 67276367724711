<template>
  <div class="login-parent">
    <!-- COVER FILL -->
    <div class="cover-fill" :class="{ 'is-fading': isFading }"></div>

    <!-- LOGIN -->
    <div class="os-login animated fadeIn">
      <div class="os-login-panel" :class="{ 'is-transit': isFading }">
        <!-- HEADER -->
        <header class="os-login-header mb-4">
          <a class="logo mb-2" href="https://www.themitchinghour.com">
            <img width="280" src="~@/assets/img/logo@2x.png" alt="The Mitching Hour" />
          </a>
        </header>

        <!-- IF FAILURE -->
        <!-- <div class="alert alert-warning alert-dismissible fade show mb-3" role="alert">
          <strong>Oops!</strong> That's incorrect.
        </div> -->

        <!-- LOGIN -->
        <form class="os-login-form">
          <!-- BUTTON -->
          <button
            type="submit"
            class="btn btn-submit"
            :disabled="isProcessing"
            @click.prevent="onAuth()"
          >
            <!-- ICON -->
            <i v-if="!isProcessing" class="fa fa-twitch mr-2"></i>
            <i v-if="isProcessing" class="fa fa-spinner fa-spin mr-2"></i>

            <!-- LOGIN TEXT -->
            <span>Login with Twitch</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import twitch from "@/config/twitch/";

  export default {
    name: "login",
    data() {
      return {
        isFading: false,
        isProcessing: false,
      };
    },
    methods: {
      /**
       * TWITCH AUTH
       */
      onAuth() {
        const { clientID } = twitch.TWITCH_CONFIG;
        const { redirectUri } = twitch.TWITCH_API;

        // Instruct redirect where to come back to.
        const desiredPathname = encodeURIComponent(this.$route.params.redirect || "/home");
        const origin = encodeURIComponent(window.location.origin);
        const state = `desiredPathname=${desiredPathname},origin=${origin}`;

        // const state = encodeURIComponent(this.$route.params.redirect || "/home");
        // let scope = "user:read:email+user:read:subscriptions";
        // const scope =
        // "user:read:email+user:read:subscriptions+channel:read:subscriptions+channel:read:hype_train";
        // const isBroadcaster = state && state !== "%2Fhome";
        // if (isBroadcaster) scope += "+channel:read:subscriptions+channel:read:hype_train";

        // v2 was overriding the scope necessary for the follow eventSub
        let scope = "user:read:email+";
        scope += "user:read:subscriptions+";
        scope += "channel:read:subscriptions+";
        scope += "channel:read:hype_train+";
        scope += "channel:manage:redemptions+";
        scope += "moderator:manage:banned_users+";
        scope += "moderator:read:chatters+";
        scope += "moderator:read:followers";

        const codeUri =
          "https://id.twitch.tv/oauth2/authorize?" +
          `client_id=${clientID}` +
          `&redirect_uri=${redirectUri}` +
          "&response_type=code" +
          `&scope=${scope.trim()}` +
          `&state=${state}`;

        this.isProcessing = true;

        // window.open(codeUri, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,width=500,height=500");
        window.open(codeUri, "_self");
      },
    },
  };
</script>

<style lang="scss" scoped>
  //-----------------------------------------------------------------
  // LOGIN PARENT
  //-----------------------------------------------------------------

  .login-parent {
    display: flex;
    height: 100%;
  }

  //-----------------------------------------------------------------
  // COVER FILL
  //-----------------------------------------------------------------

  .cover-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: black url('~@/assets/img/blurred-bg-2@2x.jpg') bottom center no-repeat;
    background: url("~@/assets/img/blurred-bg-3.jpg") bottom center no-repeat;
    background-size: cover;
    transition: opacity 2s 0s ease;
    // filter: brightness(0.3);
    filter: brightness(0.6);

    &.is-fading {
      opacity: 0;
    }
  }

  //-----------------------------------------------------------------
  // LOGIN
  //-----------------------------------------------------------------

  .os-login {
    display: flex;
    width: 100%; //450px;
    height: 100%;
    margin: auto;
    padding: $spacer * 3 $spacer * 2;
    text-align: center;
    z-index: 1;
  }

  //-----------------------------------------------------------------
  // LOGIN PANEL
  //-----------------------------------------------------------------

  .os-login-panel {
    width: 100%;
    padding: rem-calc(50 30);
    // border: 1px dashed grey;
    margin: auto;
  }

  //-----------------------------------------------------------------
  // LOGIN PANEL FORM
  //-----------------------------------------------------------------

  .os-login-form {
    display: flex;
    justify-content: center;
    // border: 1px dashed grey;

    //-----------------------------------------------------------------
    // SUBMIT BUTTON
    //-----------------------------------------------------------------

    .spinner {
      position: relative;
      top: 1px;
      width: 20px;
      height: 20px;

      .path {
        stroke: #222;
      }
    }

    //============================================
    // ANCHORS
    //============================================

    .btn-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: rem-calc(100);
      padding: rem-calc(15 20);
      border-bottom: 3px solid rgba(black, 0.5);
      border-radius: 5px;
      box-shadow: 0 4px 10px 2px rgba(black, 0.1);
      background: linear-gradient(
        to bottom,
        rgba(#9146ff, 1) 0%,
        rgba(darken(#9146ff, 10%), 0.6) 100%
      );
      // font-family: 'Oswald';
      font-size: rem-calc(16); //19
      font-weight: 600;
      line-height: 0;
      color: white;
      // text-shadow: 0 1px 0 rgba(black, 0.4);
      transition: all ease 300ms;
    }
  }
</style>
