/*
	MUTATIONS
*/
//------------------------------------------------------------
// SET DATA
//------------------------------------------------------------

const PAUSE_VIEWER_COUNT_POLLING = (state, value) => {
  state.viewerCountPollingPaused = value;
};

const SET_CHAT_BADGES = (state, payload) => {
  // https://vuex.vuejs.org/guide/mutations.html#object-style-commit
  // Vue.set(obj, 'newProp', 123)
  // Vue.set(state.twitchBadgeCache.data, payload.channel, payload.badges);
  state.twitchBadgeCache.data = {
    ...state.twitchBadgeCache.data,
    [payload.channel]: payload.data,
  };
};

const SET_FOLLOWER_COUNT = (state, value) => {
  state.followerCount = value;
};

const SET_LATEST_FOLLOWERS = (state, arr) => {
  state.latestFollowers = arr;
  state.latestFollower = arr[0].user_name;
};

const SET_SUBSCRIBER_COUNT = (state, value) => {
  state.subscriberCount = value;
};

const SET_VIEWER_COUNT = (state, value) => {
  state.viewerCount = value;
};

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  PAUSE_VIEWER_COUNT_POLLING,
  SET_CHAT_BADGES,
  SET_FOLLOWER_COUNT,
  SET_LATEST_FOLLOWERS,
  SET_SUBSCRIBER_COUNT,
  SET_VIEWER_COUNT,
};
