/*
	STATE
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

//------------------------------------------------------------
// STATE
//------------------------------------------------------------

const state = {
  followerCount: -1,
  followerGoal: 0,
  latestFollowers: [],
  latestFollower: "-",
  latestSubscribers: [],
  latestSubscriber: "-",
  viewerCount: 0,
  viewerCountPollingPaused: false,
  subscriberCount: 0,

  twitchBadgeCache: {
    data: {},
  },
};

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
