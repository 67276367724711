/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */

/**
 * SET TIMING FUNCTIONS
 *
 * Timing function is stored but not executed.
 */
export default async function setTimingFunctions({ state, dispatch }, target) {
  target.timingFunction = () => timingFunction(state, dispatch, target);
}

/**
 * TIMING FUNCTION
 */
function timingFunction(state = {}, dispatch = () => null, target = {}) {
  /**
   * 1. DELAY START
   * Remove `sleeping` prop after timeout. Timeout prop is dynamically assigned.
   */
  if (target.delayStart) {
    // A sleeping task is essentially a placeholder, this wasn't the case originally.
    // It should not trigger duration, sound or events specified by a stageComponent until wake.
    target.sleeping = true;

    // After sleep, a fresh task is sent with the previous instructions.
    target.delayStartTimeout = setTimeout(() => {
      // EDGE CASE:
      // Warning: quirky behavior with sound; thriller sfx - it sleeps, then cancels.
      // Sound adds to tasks but it detects an 'un-canceled' task and cancels the relaunching task
      dispatch("runTasks", [
        {
          uuid: target.uuid,

          // It's best practice to specify the `canceled` state with delayStart.
          // Omitting will result in a guess, potentially sending a `true` task in.
          // This may not be desirable if wanting a "blind toggle"
          // (eg.if there's something there, cancel it).

          // Edit: sideeffects with replace and override?

          canceled:
            // added this 29.07.21 - firebase can't do != true if was prev null
            ((target.canceled === null || target.canceled === 0) &&
              state.tasks.find(
                (item) =>
                  item.uuid === target.uuid && !item.canceled && !item.sleeping && item.cancelable
              )) ||
            target.canceled,

          // Remember we're sending the task back in without delay constraints.
          delayStart: false,
          sleeping: false,
        },
      ]);

      // MUST come after runTasks (is synchronous). It voids the task so it can be cleaned up.
      target.sleeping = false;
      target.canceled = true;
    }, target.delayStart);
  }

  /**
   * 2. DURATION
   */
  const hasDuration = target.duration && !target.sleeping && !target.paused;

  if (hasDuration) {
    // Account for sleeping time at start.
    const finalDurationMillis = target.duration + target.delayStart;

    target.durationTimeout = setTimeout(() => {
      if (target.delayEnd) {
        target.delayEndTimeout = setTimeout(() => concludeTasks(target, dispatch), target.delayEnd);
        return;
      }

      concludeTasks(target, dispatch);
    }, finalDurationMillis);
  }

  return true; // [LOCKED] - to test against null.
}

/**
 * CONCLUDE TAKS
 * Cancel and action any tasks on complete (if any)
 */
function concludeTasks(target, dispatch) {
  if (!target.paused) {
    dispatch("cancelTasks", [target]);

    const hasTasksOnComplete = !!target.tasksOnComplete.length;
    if (hasTasksOnComplete) {
      dispatch("runTasks", target.tasksOnComplete, target.parentId);
    }
  }
}
