/*
	STATE
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import actions from "./actions/";
import getters from "./getters";
import mutations from "./mutations";

//------------------------------------------------------------
// STATE
//------------------------------------------------------------

const state = {
  // playlist: [],
  playlistShuffled: [],

  playlists: [], // firebase

  playlistSelected: "public",

  playlistPresets: [
    // {
    // 	name: "90s Dive",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL0tLiXsS89h4NIHhxMVFnIJ",
    // 	categories: ["TV"],
    // 	isPublic: true,
    // 	isReadOnly: true,
    // },
    // {
    // 	name: "Aquarium",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Misc"],
    // 	isPublic: false,
    // },
    // {
    // 	name: "Fireplace",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Misc"],
    // 	isPublic: false,
    // },
    // {
    // 	name: "Crystal Lake",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Film"],
    // 	isPublic: false,
    // },
    // {
    // 	name: "Horror Box",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL1P2URycvgiaXqUo8VSzcY7",
    // 	categories: ["TV", "Film"],
    // 	isPublic: true,
    // 	isReadOnly: true,
    // },
    // {
    // 	name: "MTV",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["TV"],
    // 	isPublic: true
    // },
    // {
    // 	name: "Nickelodeon",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["TV"],
    // 	isPublic: true
    // },
    // {
    // 	name: "Slayerbait",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["TV", "Film"],
    // 	isPublic: true,
    // },
    {
      name: "public",
      alias: "TMH One",
      description: "The public playlist.",
      url: "",
      categories: ["Music"],
      isPublic: true,
      isReadOnly: false,
    },
    // {
    // 	name: "TMH Classic",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Music"],
    // 	isPublic: true
    // },
    // {
    // 	name: "TMH Club",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Music"],
    // 	isPublic: true,
    // },
    // {
    // 	name: "TMH Hits",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Music"],
    // 	isPublic: true,
    // },
    // {
    // 	name: "TMH Rock",
    // 	description: "",
    // 	url: "https://www.youtube.com/playlist?list=PLLCkf4eP3rL39KzejNvaBRkpPMdACdp4z",
    // 	categories: ["Music"],
    // 	isPublic: true,
    // },
  ],
};

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
