/**
 * GET ACTIVE USERS
 */
const getActiveUsers = (state) => state.activeUsers;

/**
 * EXPORT
 */
export default {
  getActiveUsers,
};
