/*
	SET FLAG
*/
//------------------------------------------------------------
// IMPORTS
//------------------------------------------------------------

import { firebaseApp } from '@/config/firebase.js';

//------------------------------------------------------------
// EXPORT
//------------------------------------------------------------

export const setFlag = ({ getters }, payload) => {

	// Make copy of array.
	const playlistClone = getters.getPlaylist.slice();

	// Potential flag data.
	const obj = {
		meta: payload.meta || null,
		type: payload.type || 'caution'
	}

	// Accept index or array of indices.
	const indices = [...[payload.index].flat()];

	indices.forEach(index => {
		const flagItem = playlistClone[index];

		// Toggle flagging.
		if (flagItem) {
			flagItem.flagged = (!flagItem.flagged || payload.force) ? obj : null;
		}
	});

	// Update database.
	firebaseApp.firestore().collection('playlists').doc('public')
	.update({
		videos: playlistClone
	});
}