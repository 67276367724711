/**
 * NOTES:
 *
 * https://firebase.google.com/docs/firestore/query-data/get-data#get_all_documents_in_a_collection
 * https://stackoverflow.com/questions/48956152/how-to-loop-through-messages-and-delete-certain-ones-in-firebase
 *
 * NOTES FROM DISCORD: You should be aware that a query snapshot has a limit of the number of documents you can get from firestore
 * this is solved by a batch process, which is a loop, that loops for each batch
 * I believe its about 100 documents, maybe 500 but 100 is safe...
 * 
 * // v4.10.0 onwards IN ADMIN ONLY
  // await firebaseApp.firestore().recursiveDelete(purgeableTasksRef);
 */

import { firebaseApp } from "../../config/firebase";

/**
 * DELETE ALL TASKS
 */
export default async function deleteAllTasks() {
  const purgeableTasksRef = firebaseApp
    .firestore()
    .collection("tasks")
    .where("purgeable", "==", true);

  const batch = firebaseApp.firestore().batch();

  try {
    const querySnapshot = await purgeableTasksRef.get();

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    await batch.commit();
    return [true, null];
  } catch (err) {
    return [false, err];
  }
}
